import 'es6-promise/auto'
import 'url-polyfill'
import 'whatwg-fetch'

import Vue from 'vue'

import VueSecureHTML from 'vue-html-secure'
import VuePapaParse from 'vue-papa-parse'

import { FeatFlag } from '@/mixins/feat-flag/feat-flag'
import appInsights from '@/plugins/appInsights'
import vuetify from '@/plugins/vuetify'

import App from './App.vue'
import filters from './filters'
import router from './router/index'
import { loadToken } from './services/authentication.service'
import store from './store/index'
import warnHandler from './warnHandle'

import './plugins'
import '@/plugins/sentry'

import '@juntossomosmais/atomium-tokens/dist/tokens.css'

Vue.config.productionTip = false

Vue.config.warnHandler = warnHandler
appInsights.loadAppInsights()
appInsights.trackPageView()
// Gets and initialize the token for the first time
loadToken()
Vue.mixin(FeatFlag)
Vue.use(VuePapaParse)
Vue.use(VueSecureHTML)

Vue.prototype.$safeHTML = VueSecureHTML.safeHTML

console.log(
  `Hi JSM lover, ${process.env.VUE_APP_ENV} environment is running.`
)

new Vue({
  router,
  store,
  filters,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
