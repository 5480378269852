export function convertCookieToArray(cookie) {
  return cookie.split(';')
}

export function findChaordicSessionInCookie(cookieToArray) {
  const regexToFinChaordicCookie = /chaordic_session=[^;]+/

  return cookieToArray.find((str) => str.match(regexToFinChaordicCookie))
}

export function removeValueBeforeSessionChaordic(chaordicSession) {
  const regexToExcludeValueBefore = new RegExp(/chaordic_se/)

  return chaordicSession?.replace(regexToExcludeValueBefore, '').trim() || ''
}

export function pipesToGetSessionId(cookie, ...cookieHelpers) {
  return cookieHelpers.reduce((res, helper) => {
    return helper(res)
  }, cookie)
}

export default function getSessionId() {
  return pipesToGetSessionId(
    document.cookie,
    convertCookieToArray,
    findChaordicSessionInCookie,
    removeValueBeforeSessionChaordic
  )
}
