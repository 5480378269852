import Vue from 'vue'

import Router from 'vue-router'

import { userHasPermissions } from '@/plugins/user-roles'
import { isAuthenticated } from '@/services/authentication.service'
import storage from '@/services/localstorage.service'

import store from '../store'
import * as typesBreadcrumb from '../store/modules/breadcrumb/types'
import * as types from '../store/modules/tab-system/types'

import ForgotPassword from './forgot-password/ForgotPassword'
import Login from './login/Login'
import Main from './main/Main'
import MfaRequired from './mfa/MfaRequired'

/**
 * Work around to fix the error "NavigationDuplicated Navigating to current location is not allowed [vuejs]""
 *
 * Vue Router and TypeScript: How to solve navigation duplicated error?
 * https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onComplete, onAbort) {
  if (onComplete || onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [Main, ForgotPassword, Login, MfaRequired],
})

router.beforeEach(async (to, from, next) => {
  if (to.query.error_message && to.name !== 'Login') {
    storage.clear()
    return next({
      name: 'Login',
      query: { error_message: to.query.error_message },
    })
  }
  if (!to.query.access_token) {
    const hasPermission = await userHasPermissions(to)

    if (!hasPermission) {
      Vue.notification.error('Usuário sem permissão para acessar essa página')
      storage.clear()
      return next({ name: 'Login' })
    }
  }

  store.commit(types.NAMESPACE + types.MUT_CLEAR_TABS, { to, from })

  if (to.matched.some((record) => record.meta.authenticate)) {
    if (!isAuthenticated()) {
      storage.clear()
      return next({
        name: 'Login',
        query: { access_token: to.query.access_token },
      })
    }
  }

  if (to.matched.some((record) => record.meta.breadcrumb)) {
    store.commit(
      typesBreadcrumb.NAMESPACE + typesBreadcrumb.MUT_SET_BREADCRUMB,
      to.meta.breadcrumb
    )
  }

  return next()
})

export default router
