import guid from '@/helpers/guid'
import loadingObservable from '@/services/config/observable/loading.observable.js'

export const JSM_LOADING_HEADER = 'JSM_REQUEST_ID'

const LoadingInterceptor = {
  request(config) {
    if (config?.headers?.loading !== false) {
      const requestId = guid()
      config.headers[JSM_LOADING_HEADER] = requestId
      loadingObservable.updateArray(requestId)
    }
    return config
  },
  response(response) {
    if (response?.config) {
      const requestId = response?.config?.headers[JSM_LOADING_HEADER]
      loadingObservable.updateArray(requestId, true)
    }
    return response
  },
  error(error) {
    if (error?.response?.config) {
      const requestId = error?.response?.config?.headers[JSM_LOADING_HEADER]
      loadingObservable.updateArray(requestId, true)
    }

    return Promise.reject(error)
  },
}
export default LoadingInterceptor
