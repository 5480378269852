const Recategorization = () =>
  import(
    /* webpackChunkName: "recategorization" */ '../../views/Recategorization/Recategorization.vue'
  )

export default {
  name: 'Recategorization',
  path: 'recategorization',
  component: Recategorization,
  meta: {
    authenticate: true,
    clearStateOnChange: true,
    breadcrumb: [
      { name: 'Regras e Definições', pathName: '' },
      { name: 'Categorização', pathName: 'ListCategories' },
      { name: 'Ordenar Categoria', pathName: 'OrderCategory' },
    ],
  },
}
