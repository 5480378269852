const Promotions = () =>
  import(
    /* webpackChunkName: "promotions" */ '@/views/Promotions/Promotions.vue'
  )
const PromotionsList = () =>
  import(
    /* webpackChunkName: "promotions" */ '@/views/Promotions/components/PromotionsList/PromotionsList.vue'
  )

const PromotionsNew = () =>
  import(
    /* webpackChunkName: "promotions-new" */ '@/views/Promotions/components/PromotionsNew/PromotionsNew.vue'
  )

import { Mode } from '@/enums/environments.js'
import { useOnlyAtMode } from '@/mixins/feat-flag/feat-flag'

export default {
  name: 'Promotions',
  path: 'promotions',
  ...(useOnlyAtMode(Mode.Prod) && { redirect: '404' }),
  component: Promotions,
  meta: {
    authenticate: true,
    clearStateOnChange: true,
    breadcrumb: [{ name: 'Regras e Definições', pathName: '' }],
  },
  children: [
    {
      /* to-do [promotions]: Remove feat flag when all is done*/
      ...(useOnlyAtMode(Mode.Prod) && { redirect: '404' }),
      path: 'list',
      name: 'PromotionsList',
      component: PromotionsList,
      meta: {
        authenticate: true,
        clearStateOnChange: true,
        breadcrumb: [{ name: 'Regras e Definições', pathName: '' }],
      },
    },
    {
      /* to-do [promotions]: Remove feat flag when all is done*/
      ...(useOnlyAtMode(Mode.Prod) && { redirect: '404' }),
      path: 'new',
      name: 'PromotionsNew',
      component: PromotionsNew,
      meta: {
        authenticate: true,
        clearStateOnChange: true,
        breadcrumb: [
          { name: 'Regras e Definições', pathName: '' },
          { name: 'Promoções', pathName: 'Promotions' },
        ],
      },
    },
  ],
}
