/* eslint-disable no-param-reassign */
import * as types from './types'

const mutations = {
  [types.MUT_SET_TAB](state, newTab) {
    state[types.STATE_TABS] = [...state[types.STATE_TABS], newTab]
  },

  [types.MUT_UNSET_TABS](state) {
    const tabs = state[types.STATE_TABS]

    for (let i = 0; i < tabs.length; i += 1) tabs[i].active = false
  },

  [types.MUT_CHANGE_TAB](state, tab) {
    const tabs = state[types.STATE_TABS]
    const idx = tabs.findIndex((t) => t === tab)

    for (let i = 0; i < tabs.length; i += 1) tabs[i].active = false

    tabs[idx].active = true
  },

  [types.MUT_REMOVE_TAB](state, tab) {
    const tabs = state[types.STATE_TABS]
    const idx = tabs.findIndex((t) => t === tab)

    if (!state[types.STATE_TABS][0]) return

    state[types.STATE_TABS][0].active = true
    state[types.STATE_TABS].splice(idx, 1)
  },

  [types.MUT_SET_TAB_INFO](state, tabInfo) {
    const tabs = state[types.STATE_TABS]
    const idx = tabs.findIndex((tab) => tab.id === tabInfo.tabId)

    tabs[idx].info = tabInfo
  },

  [types.MUT_FORCE_CLEAR_TABS](state) {
    const tabs = state[types.STATE_TABS]

    tabs.length = 0
  },

  [types.MUT_CLEAR_TABS](state, { to, from }) {
    const tabs = state[types.STATE_TABS]

    const idx = tabs.findIndex((tab) => to.matched[1] === tab.info.rootView)

    if (idx > -1) {
      if (from.matched[1] !== tabs[idx].info.rootView) tabs.length = 0
    } else {
      tabs.length = 0
    }
  },
}

export default mutations
