import Vue from 'vue'

const INTERNAL_SERVER_ERROR_HTTP_STATUS = 500

// This is the same message used at BE
const CURRENT_DEFAULT_MESSAGE =
  'Erro ao processar requisição. Verifique os valores passados e tente novamente.'

export const errorInterceptor = (error) => {
  const { status } = error?.response ?? { status: '' }
  const { errors = [] } = error?.response?.data ?? { errors: [] }

  if (status === INTERNAL_SERVER_ERROR_HTTP_STATUS) {
    Vue.notification.error(errors?.[0] || CURRENT_DEFAULT_MESSAGE)
  }
  return Promise.reject(error)
}
