const ActionRegions = () =>
  import(
    /* webpackChunkName: "action-regions" */ '@/views/action-regions/ActionRegions.vue'
  )
const ListActionRegion = () =>
  import(
    /* webpackChunkName: "action-regions" */ '@/views/distribution-center/components/list-distribution-center/ListDistributionCenter.vue'
  )

export default {
  path: '/action-regions',
  name: 'ActionRegions',
  component: ActionRegions,
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'ListActionRegions',
      component: ListActionRegion,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Regiões de Atuação', pathName: 'ListActionRegions' },
        ],
        claims: ['SellerAdmin.All', 'UserAdmin.All'],
      },
    },
  ],
}
