import { Vue } from 'vue-property-decorator'

import { Mode } from '@/enums/environments'

// use as helper at files have imported it.
const useOnlyAtMode = (...envs) => {
  return envs.some((env) => env === process.env.VUE_APP_ENV)
}

// use as mixin at Vue component
const FeatFlag = Vue.extend({
  computed: {
    $modes() {
      return Mode
    },
  },
  methods: {
    $useOnlyAtMode(...args) {
      return useOnlyAtMode(...args)
    },
  },
})

export { useOnlyAtMode, FeatFlag }
