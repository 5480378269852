<template>
  <VApp class="app" id="app">
    <router-view />
  </VApp>
</template>
<script>
export default {
  methods: {
    removeTemporaryToken() {
      this.$persistence.remove(process.env.VUE_APP_TEMPORARY_TOKEN)
    },
  },
}
</script>

<style lang="scss" src="./App.scss" />
