//Colors of new style guide colors that will be deleted after Venice update.
//Thread slack about it: https://juntossomosmais.slack.com/archives/C01BE461UD8/p1627410555431000
const orangePrimaryRegular = '#ed6700'
const redPrimaryRegular = '#C62828'
const redPrimaryLight = '#FCF2F3'
const greenPrimaryRegular = '#388E3C'
const grayPrimaryRegular = '#f1f3f3'

const orange1 = '#ff7d00'
const blue2 = '#1976d2'
const green1 = '#168806'
const red2 = '#d60000'

const theme = {
  colors: {
    black: '#000',
    white: '#fff',
    gray1: '#f5f5f5',
    gray2: '#d8d8d8',
    gray3: '#9b9b9b',
    gray4: '#4a4a4a',
    gray5: '#1f2328',
    blue1: '#e4f0fc',
    blue2,
    blue3: '#1976d2',
    blue4: '#12579b',
    blue5: '#1976D2',
    green1,
    orange1,
    purple1: '#bd10e0',
    red1: '#b60000',
    red2,
    primaryColor: orange1,
    secondaryColor: blue2,
    successColor: green1,
    dangerColor: red2,
    orangePrimaryRegular,
    redPrimaryRegular,
    redPrimaryLight,
    greenPrimaryRegular,
    grayPrimaryRegular,
  },
  spaces: {
    space: '24px',
    md: '32px',
  },
}

export default theme
