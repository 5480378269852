import { maxZIndex } from '../../helpers/functions'

import JsmNotification from './src/JsmNotification.vue'

JsmNotification.install = (Vue) => {
  let instance
  const instances = []
  const vue = Vue
  const offsetHeightBetweenNotifications = 20

  const notification = {
    show(args) {
      const { message, title, type, autoclose, timer } = args
      const JsmNotificationConstructor = Vue.extend(JsmNotification)

      const initInstance = () => {
        instance = new JsmNotificationConstructor({
          el: document.createElement('div'),
        })
        if (message) instance.$props.message = message
        if (type) instance.$props.type = type
        if (title) instance.$props.title = title
        if (autoclose) instance.$props.autoclose = autoclose
        if (timer) instance.$props.timer = timer
      }

      initInstance()

      instance.visible = true

      instances.push(instance)

      let verticalOffset = 20

      Vue.nextTick(() => {
        for (let i = 0; i < instances.length; i += 1) {
          const actualInstance = instances[i]
          const previousInstance = instances[i - 1]

          if (previousInstance) {
            verticalOffset += previousInstance.$el.offsetHeight
          }

          actualInstance.$el.style.zIndex = maxZIndex() + 1

          actualInstance.$el.style.top = `${verticalOffset}px`

          verticalOffset += offsetHeightBetweenNotifications

          document.body.appendChild(actualInstance.$el)
        }
      })
    },

    close(inst) {
      const idx = instances.findIndex((i) => i === inst)
      const removedElementHeight = instances[idx]?.$el.offsetHeight

      if (idx !== -1) {
        instances[idx].visible = false
      }

      if (instances.length < 1) return

      for (let i = idx; i < instances.length; i += 1) {
        const item = instances[i]
        item.$el.style.top = `${
          item.$el.getBoundingClientRect().top -
          removedElementHeight -
          offsetHeightBetweenNotifications
        }px`
      }

      instances.splice(idx, 1)
    },

    success(message) {
      const args = {
        message,
        title: 'Sucesso!',
        type: 'success',
      }

      this.show(args)
    },

    warning(message) {
      const args = {
        message,
        title: 'Atenção!',
        type: 'warning',
      }

      this.show(args)
    },

    error(
      message = 'Houve um erro no servidor, por gentileza tente novamente.'
    ) {
      const args = {
        message,
        title: 'Erro!',
        type: 'error',
      }

      this.show(args)
    },
  }
  vue.notification = notification

  vue.prototype.$notification = notification
}

export default JsmNotification
