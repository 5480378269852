const ListRestrictions = () =>
  import(
    /* webpackChunkName: "restrictions" */ '../../views/restrictions/components/list-restrictions/ListRestrictions.vue'
  )
const NewRestriction = () =>
  import(
    /* webpackChunkName: "restrictions" */ '../../views/restrictions/components/new-restriction/NewRestriction.vue'
  )
const Restrictions = () =>
  import(
    /* webpackChunkName: "restrictions" */ '../../views/restrictions/Restrictions.vue'
  )

export default {
  path: 'restrictions',
  component: Restrictions,
  meta: { authenticate: true },
  children: [
    {
      name: 'ListRestrictions',
      path: 'list',
      component: ListRestrictions,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Restrições', pathName: 'ListRestrictions' },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'NOVA RESTRIÇÃO',
            to: 'NewRestriction',
            tabTitle: 'NOVA RESTRIÇÃO',
          },
        ],
        claims: ['RestrictionAdmin.All'],
      },
    },
    {
      name: 'NewRestriction',
      path: 'new',
      component: NewRestriction,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Restrições', pathName: 'ListRestrictions' },
          { name: 'Nova Restrição', pathName: 'NewRestriction' },
        ],
        claims: ['RestrictionAdmin.All'],
      },
    },
  ],
}
