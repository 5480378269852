import Vue from 'vue'

import * as JsmComponents from './jsm-components'
import { install as SellerHandler } from './seller-handler'
import StorageApi from './storage'
import { install as UserRoles } from './user-roles'
import VueMob from './vuemob'

Vue.use(VueMob)
Vue.use(JsmComponents.JsmButton)
Vue.use(JsmComponents.JsmInput)
Vue.use(JsmComponents.JsmCard)
Vue.use(JsmComponents.JsmBreadcrumb)
Vue.use(JsmComponents.JsmCheckbox)
Vue.use(JsmComponents.JsmRecaptcha)
Vue.use(JsmComponents.JsmSelect)
Vue.use(JsmComponents.JsmTable)
Vue.use(JsmComponents.JsmLoading)
Vue.use(JsmComponents.JsmModal)
Vue.use(JsmComponents.JsmNotification)
Vue.use(JsmComponents.JsmMetter)
Vue.use(SellerHandler)

Vue.use(StorageApi)
Vue.use(VueMob)
Vue.use(UserRoles)
