import Vue from 'vue'

import Vuetify from 'vuetify'

import theme from '@/styles/theme'

Vue.use(Vuetify)

const SM_BREAKPOINT = 768

const vuetify = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: SM_BREAKPOINT,
      md: 992,
      lg: 1280,
    },
    scrollBarWidth: 24,
  },
  theme: {
    options: { customProperties: true },
    themes: { light: theme.colors },
  },
})

export default vuetify
