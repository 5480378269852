export default {
  set: (key, data) => {
    if (typeof data === 'string') {
      localStorage.setItem(key, data)
    } else {
      localStorage.setItem(key, JSON.stringify(data))
    }
  },

  get: (key) => {
    const rawData = localStorage.getItem(key)
    let data = ''

    try {
      data = JSON.parse(rawData)
    } catch (_) {
      data = rawData
    }

    return data
  },

  remove: (key) => {
    localStorage.removeItem(key)
  },

  clear: () => {
    localStorage.clear()
  },
}
