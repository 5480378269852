<template>
  <div
    class="jsm-accordion__section"
    :class="`jsm-accordion__section--${size}`"
  >
    <div
      :style="{ backgroundColor: background }"
      class="jsm-accordion__section-header"
      @click="handleChild"
    >
      <p class="jsm-accordion__section-header-name">{{ name }}</p>
      <div
        class="jsm-accordion__section-header-plus-icon"
        :class="{ opened: showChild }"
      >
        <plus-icon />
      </div>
    </div>
    <div class="jsm-accordion__child" :class="{ opened: showChild }">
      <transition name="fade">
        <slot v-if="showChild"></slot>
      </transition>
    </div>
  </div>
</template>

<script>
import getCssVariableValue from '@/helpers/get-css-variable-value'

import PlusIcon from './assets/images/plus.svg'

export default {
  name: 'JsmAccordion',

  components: {
    PlusIcon,
  },

  props: {
    name: { type: String, default: '' },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium'].indexOf(value) !== -1,
    },
    background: {
      type: String,
      default: getCssVariableValue('--color-neutral-white'),
    },
  },

  data() {
    return {
      showChild: false,
    }
  },

  methods: {
    handleChild() {
      this.showChild = !this.showChild
    },
  },
}
</script>

<style src="./JsmAccordion.styl" lang="stylus" scoped />
