const Banners = () =>
  import(/* webpackChunkName: "banners" */ '../../views/banners/Banners.vue')
const ListBanners = () =>
  import(
    /* webpackChunkName: "banners" */ '../../views/banners/components/list-banners/ListBanners.vue'
  )
const OrderBanners = () =>
  import(
    /* webpackChunkName: "banners" */ '../../views/banners/components/order-banners/OrderBanners.vue'
  )
const EditBanner = () =>
  import(
    /* webpackChunkName: "banners" */ '../../views/banners/components/edit-banner/EditBanner.vue'
  )

export default {
  path: 'banners',
  component: Banners,
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'ListBanners',
      component: ListBanners,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Banners', pathName: 'ListBanners' },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'CADASTRAR BANNER',
            to: 'NewBanner',
            tabTitle: 'CADASTRAR BANNER',
          },
          {
            type: 'nude-primary',
            text: 'ORDENAR',
            to: 'OrderBanners',
            tabTitle: 'ORDENAR BANNERS',
          },
        ],
        claims: ['MktMessageAdmin.All', 'BannerAdmin.All'],
      },
    },
    {
      path: 'new',
      name: 'NewBanner',
      component: EditBanner,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Banners', pathName: 'ListBanners' },
          { name: 'Novo Banner', pathName: 'NewBanner' },
        ],
        claims: ['MktMessageAdmin.All', 'BannerAdmin.All'],
      },
    },
    {
      path: 'order',
      name: 'OrderBanners',
      component: OrderBanners,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Banners', pathName: 'ListBanners' },
          { name: 'Ordenar Banners', pathName: 'OrderBanners' },
        ],
        claims: ['MktMessageAdmin.All', 'BannerAdmin.All'],
      },
    },
  ],
}
