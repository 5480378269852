import Vue from 'vue'

import { storageKeys } from '@/enums/storageKeys'

import getSessionId from '../helpers/GetSessionId'

import storage from './localstorage.service'

function hasTemporaryToken() {
  return !!storage.get(process.env.VUE_APP_TEMPORARY_TOKEN)
}

export function initBuilder({ method, body, headers }) {
  let token

  if (hasTemporaryToken()) {
    token = storage.get(process.env.VUE_APP_TEMPORARY_TOKEN)
  } else {
    token = storage.get(storageKeys.TOKEN) || ''
  }

  if (method.toUpperCase() === 'GET') {
    return {
      method,
      Origin: window.location.origin,
      headers: {
        authorization: `Bearer ${token}`,
        sessionid: getSessionId(),
        ...headers,
      },
      credentials: 'include',
    }
  }

  return {
    method,
    Origin: window.location.origin,
    headers: {
      authorization: `Bearer ${token}`,
      sessionid: getSessionId(),
      ...headers,
    },
    credentials: 'include',
    body: body instanceof FormData ? body : JSON.stringify(body),
  }
}

export function queryUrlBuilder(endpoint, params, route) {
  const url = new URL(endpoint)

  const search = new URLSearchParams()

  if (params) {
    Object.keys(params).forEach((param) => {
      if (Array.isArray(params[param])) {
        params[param].forEach((p) => search.append(param, p))
      } else {
        search.append(param, params[param])
      }
    })
  }

  url.search = search

  if (route) {
    url.pathname += `/${route}`
  }

  return url
}

async function fetchFunction(url, init, defaultError) {
  let response
  let responseBody

  try {
    response = await fetch(url, init)
    if (response.status === 401) {
      storage.clear()

      Vue.notification.error('Por gentileza, faça login novamente!')

      return {}
    }

    try {
      responseBody = await response.json()
      response.data = responseBody
    } catch (_) {
      response.data = { ...response }
    }

    const { success = response.ok, data = null, errors = [] } = response.data

    if (!success) {
      if (defaultError) {
        if (errors.length) {
          errors.forEach((error) => Vue.notification.error(error))
        } else {
          Vue.notification.error()
        }
      }
    }

    return {
      success,
      errors,
      data,
    }
  } catch (error) {
    Vue.notification.error()

    if (process.env.NODE_ENV === 'development') {
      console.error(error)
    }

    return {
      success: false,
      data: null,
      errors: [],
    }
  }
}

async function request({
  method = 'GET',
  body,
  endpoint,
  params,
  headers = { 'Content-Type': 'application/json' },
  route,
  defaultError = true,
}) {
  const init = initBuilder({ method, body, headers })

  const needUrlBuild = ['GET', 'DELETE', 'PUT']

  const url = needUrlBuild.includes(method.toUpperCase())
    ? queryUrlBuilder(endpoint, params, route)
    : endpoint

  return fetchFunction(url, init, defaultError)
}

export default request
