export default {
  path: '/industry',
  name: 'industry',
  component: () =>
    import(/* webpackChunkName: "industry" */ '@/views/industry/Industry.vue'),
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'IndustryList',
      component: () =>
        import(
          /* webpackChunkName: "industry" */ '@/views/industry/components/industry-list/IndustryList.vue'
        ),
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Indústrias', pathName: 'IndustryList' },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'CADASTRAR INDÚSTRIA',
            to: 'NewIndustry',
            tabTitle: 'NOVA INDÚSTRIA',
          },
        ],
        claims: ['SellerAdmin.All'],
      },
    },
    {
      path: 'new',
      name: 'NewIndustry',
      component: () =>
        import(
          /* webpackChunkName: "industry" */ '@/views/industry/components/new-industry/NewIndustry.vue'
        ),
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Indústrias', pathName: 'IndustryList' },
          { name: 'Nova Indústria', pathName: 'NewIndustry' },
        ],
        claims: ['SellerAdmin.All'],
      },
    },
  ],
}
