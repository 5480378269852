import Main from '@/views/main/Main.vue'

import AccessAs from '../access-as/AccessAs'
import ActionRegions from '../action-regions/ActionRegions'
import Banners from '../banners/Banners'
import Carousels from '../carousels/Carousels'
import Categorization from '../categorization/Categorization'
import DeliveryRegion from '../delivery-region/DeliveryRegion'
import DistributionCenter from '../distribution-center/DistributionCenter'
import Industry from '../industry/Industry'
import Institutional from '../institutional/Institutional'
import Products from '../products/Products'
import Promotions from '../promotions/Promotions'
import Recategorization from '../recategorization/Recategorization'
import Reports from '../reports/Reports'
import RestrictionRegions from '../restriction-regions/RestrictionRegions'
import Restrictions from '../restrictions/Restrictions'
import User from '../user/User'

export default {
  path: '/',
  name: 'Main',
  component: Main,
  meta: {
    authenticate: true,
  },
  redirect: (to) => {
    return to.query.access_token
      ? {
          name: 'Login',
          query: to.query,
        }
      : { name: 'ListAccessAs' }
  },
  children: [
    { ...AccessAs, meta: { ...AccessAs.meta, clearOnChange: true } },
    { ...User, meta: { ...User.meta, clearOnChange: true } },
    { ...Industry, meta: { ...Industry.meta, clearOnChange: true } },
    {
      ...DistributionCenter,
      meta: { ...DistributionCenter.meta, clearOnChange: true },
    },
    { ...Reports, meta: { ...Reports.meta, clearOnChange: true } },
    {
      ...Categorization,
      meta: { ...Categorization.meta, clearOnChange: true },
    },
    { ...Products, meta: { ...Products.meta, clearOnChange: true } },
    {
      ...RestrictionRegions,
      meta: { ...RestrictionRegions.meta, clearOnChange: true },
    },
    { ...Restrictions, meta: { ...Restrictions.meta, clearOnChange: true } },
    { ...Banners, meta: { ...Banners.meta, clearOnChange: true } },
    { ...Carousels, meta: { ...Carousels.meta, clearOnChange: true } },
    { ...Institutional, meta: { ...Institutional.meta, clearOnChange: true } },
    { ...ActionRegions, meta: { ...ActionRegions.meta, clearOnChange: true } },
    {
      ...Recategorization,
      meta: { ...ActionRegions.meta, clearOnChange: true },
    },
    {
      ...Promotions,
      meta: { ...Promotions.meta, clearOnChange: true },
    },
    {
      ...DeliveryRegion,
      meta: { ...DeliveryRegion.meta, clearOnChange: true },
    },
  ],
}
