const MfaRequired = () =>
  import(
    /* webpackChunkName: "mfa-required" */ '../../views/mfa-required/MfaRequired.vue'
  )

export default {
  path: '/mfa-required',
  name: 'MfaRequired',
  component: MfaRequired,
  props: (route) => ({ token: route.query.token }),
}
