const UserList = () =>
  import(
    /* webpackChunkName: "restrictions" */ '../../views/user/components/user-list/UserList.vue'
  )
const NewUser = () =>
  import(
    /* webpackChunkName: "restrictions" */ '../../views/user/components/new-user/NewUser.vue'
  )
const User = () =>
  import(/* webpackChunkName: "restrictions" */ '../../views/user/User.vue')
import EventBus from '../../helpers/event-bus'

export default {
  path: 'user',
  component: User,
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'UserList',
      component: UserList,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Cadastro de Usuários', pathName: 'UserList' },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'CADASTRAR USUÁRIO',
            to: 'NewUser',
            tabTitle: 'CADASTRAR USUÁRIO',
          },
          {
            type: 'nude-success',
            text: 'CADASTRO MASSIVO',
            to: 'UserList',
            haveAction: true,
            callback: () => {
              EventBus.$emit('triggerMassiveUsersUpload')
            },
          },
        ],
        claims: ['UserAdmin.All'],
      },
    },
    {
      path: 'new',
      name: 'NewUser',
      component: NewUser,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Cadastro de Usuários', pathName: 'UserList' },
          { name: 'Novo Usuário', pathName: 'NewUser' },
        ],
        claims: ['UserAdmin.All'],
      },
    },
  ],
}
