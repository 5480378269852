<template>
  <div class="jsm-input-wrapper">
    <div
      :class="{
        'jsm-input-inner-wrapper--form-control': styleType === 'form-control',
        'jsm-input-inner-wrapper': styleType === 'material',
      }"
    >
      <component
        class="jsm-input-inner-wrapper__prefix"
        :class="{
          'jsm-input-inner-wrapper__prefix--default': !withError,
          'jsm-input-inner-wrapper__prefix--danger': withError,
          'jsm-input-inner-wrapper__prefix--small': size === 'small',
        }"
        v-if="prefix"
        :is="prefix"
      />
      <input
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
        @keydown="$emit('keydown', $event)"
        @keypress="$emit('keypress', $event)"
        @keyup="$emit('keyup', $event)"
        @click="handleClick"
        @change="$emit('change', $event)"
        :name="name"
        :id="id"
        :class="{
          'jsm-input__input': styleType === 'material',
          'jsm-input__input--nude': styleType === 'nude',
          'jsm-input__input--small': size === 'small',
          'jsm-input__input--form-control': styleType === 'form-control',
          'jsm-input__input--with-prefix': prefix,
          'jsm-input__input--with-suffix': suffix,
          'jsm-input__input--danger': withError,
          'jsm-input__input--select-behavior': selectBehavior,
        }"
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled || selectBehavior"
        :maxlength="maxlength"
        :minlength="minlength"
        :max="maxDate"
        v-maskCnpj="maskCnpj"
        v-maskCpf="maskCpf"
        v-maskHibrid="maskHibrid"
        v-maskCurrency="maskCurrency"
        v-maskCep="maskCep"
        v-maskPhone="maskPhone"
        :style="{ paddingRight }"
      />
      <label
        for="input"
        :class="{
          'jsm-input__label': styleType === 'material',
          'jsm-input__label--form-control': styleType === 'form-control',
          'jsm-input__label--with-prefix': prefix,
          'jsm-input__label--with-suffix': suffix,
          'jsm-input__label--fix-alignment': labelAlignment,
        }"
        >{{ label }}</label
      >
      <div
        @click="$emit('suffixClick')"
        @mousedown="$emit('suffixMouseDown')"
        @mouseup="$emit('suffixMouseUp')"
        @mouseout="$emit('suffixMouseOver')"
        @touchstart="$emit('suffixTouchStart')"
        @touchend="$emit('suffixTouchEnd')"
        class="jsm-input-inner-wrapper_suffix"
        :class="{
          'jsm-input-inner-wrapper__suffix--date-type': type === 'date',
        }"
      >
        <component
          class="jsm-input-inner-wrapper__suffix"
          :class="{
            'jsm-input-inner-wrapper__suffix--small': size === 'small',
          }"
          :style="{
            maxWidth: suffixMaxWidth,
            fill: suffixColor,
            ...suffixStyle,
          }"
          v-if="suffix"
          :is="suffix"
        />
      </div>
    </div>
    <p
      v-if="errorMessage"
      class="jsm-input__error-message"
      :class="`jsm-input__error-message--${name}`"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'JsmInput',

  props: {
    name: { type: String, default: '' },
    prefix: { default: () => {} },
    suffix: { default: () => {} },
    title: { type: String, default: '' },
    type: { type: String, default: 'text' },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium'].indexOf(value) !== -1,
    },
    styleType: {
      type: String,
      default: 'material',
      validator: (value) =>
        ['material', 'form-control', 'nude'].indexOf(value) !== -1,
    },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false },
    withError: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    suffixMaxWidth: { type: String, default: '' },
    maxlength: { type: Number, default: 50 },
    minlength: { type: Number, default: 4 },
    maskCnpj: { type: Boolean, default: false },
    maskCpf: { type: Boolean, default: false },
    maskCep: { type: Boolean, default: false },
    maskHibrid: { type: Boolean, default: false },
    maskCurrency: { type: Boolean, default: false },
    maskPhone: { type: Boolean, default: false },
    suffixColor: { type: String, default: '' },
    selectBehavior: { type: Boolean, default: false },
    scrollOnClickWhenMobile: {
      type: Boolean,
      default: window.innerWidth < 800,
    },
    maxDate: {
      type: [Number, Date, String],
      default: '2199-12-31',
    },
    suffixStyle: { type: Object, default: () => {} },
    paddingRight: { type: String, default: '' },
    labelAlignment: { type: Boolean, default: false },
  },

  directives: {
    maskCnpj: {
      update: (el, binding) => {
        if (binding.value === true) {
          const element = el
          let cnpj = el.value

          cnpj = cnpj.replace(/\D/g, '')
          cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2')
          cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
          cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2')
          cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2')

          element.value = cnpj
        }
      },
    },

    maskCpf: {
      update: (el, binding) => {
        if (binding.value === true) {
          const element = el
          let cpf = el.value

          cpf = cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')

          element.value = cpf
        }
      },
    },

    maskCurrency: {
      update: (el, binding) => {
        if (binding.value === true) {
          const element = el
          let currency = el.value

          currency = currency.replace('R$', '').trim()
          currency = currency.replace(/\D/g, '')
          currency = +currency / 100

          currency = Intl.NumberFormat('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }).format(currency)

          element.value = currency
        }
      },
    },
    maskPhone: {
      update: (el, binding) => {
        if (binding.value === true) {
          const element = el
          let phone = element.value

          phone = phone.replace(/\D/g, '')
          phone = phone.replace(/^(\d{2})(\d)/, '($1) $2')
          phone = phone.replace(/(\d)(\d{4})$/, '$1-$2')

          element.value = phone
        }
      },
    },
    maskCep: {
      update: (el, binding) => {
        if (binding.value === true) {
          const element = el
          let cep = el.value
          cep = cep.replace(/\D/g, '')
          cep = cep.replace(/^(\d{5})(\d)/, '$1-$2')
          element.value = cep
        }
      },
    },
    maskHibrid: {
      update: (el, binding) => {
        if (binding.value === true) {
          const element = el
          let hibrid = el.value
          hibrid = hibrid.replace(/\D/g, '')
          if (hibrid.length <= 14) {
            hibrid = hibrid.replace(/(\d{3})(\d)/, '$1.$2')
            hibrid = hibrid.replace(/(\d{3})(\d)/, '$1.$2')
            hibrid = hibrid.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
            element.value = hibrid
          } else {
            hibrid = hibrid.replace(/^(\d{2})(\d)/, '$1.$2')
            hibrid = hibrid.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
            hibrid = hibrid.replace(/\.(\d{3})(\d)/, '.$1/$2')
            hibrid = hibrid.replace(/(\d{4})(\d)/, '$1-$2')
            element.value = hibrid
          }
        }
      },
    },
  },
  data() {
    return {
      id: Math.random(1, 100).toString(),
    }
  },

  methods: {
    handleClick($event) {
      const rects = $event.target.getBoundingClientRect()

      if (this.scrollOnClickWhenMobile) {
        setTimeout(() => {
          window.scrollBy({
            top: rects.top,
            behavior: 'smooth',
          })
        }, 400)
      }
      this.$emit('click', $event)
    },
  },
}
</script>

<style src="./JsmInput.styl" lang="stylus" scoped />
