import { userRolesEnum } from '@/enums/userRoles'

export const checkUserIsJSMAdmin = (userRoles) => {
  return userRoles?.some((role) => role === userRolesEnum.JSMAdmin)
}

export const checkUserIsAdmin = (userRoles) => {
  return userRoles?.some(
    (role) => role === userRolesEnum.Admin || role === userRolesEnum.MasterAdmin
  )
}
