<template>
  <transition name="fade">
    <div
      v-if="visible"
      id="jsm-modal-wrapper"
      class="jsm-modal-wrapper"
      @click="close"
    >
      <slot :text="message"></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'JsmModal',

  props: {
    message: { type: String, default: '' },
  },

  data() {
    return {
      visible: false,
    }
  },

  methods: {
    close($event) {
      if ($event.target.id !== 'jsm-modal-wrapper') return
      this.$modal.close()
    },
  },
}
</script>

<style lang="stylus" src="./JsmModal.styl" scoped />
