var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jsm-input-wrapper"},[_c('div',{class:{
      'jsm-input-inner-wrapper--form-control': _vm.styleType === 'form-control',
      'jsm-input-inner-wrapper': _vm.styleType === 'material',
    }},[(_vm.prefix)?_c(_vm.prefix,{tag:"component",staticClass:"jsm-input-inner-wrapper__prefix",class:{
        'jsm-input-inner-wrapper__prefix--default': !_vm.withError,
        'jsm-input-inner-wrapper__prefix--danger': _vm.withError,
        'jsm-input-inner-wrapper__prefix--small': _vm.size === 'small',
      }}):_vm._e(),_c('input',{directives:[{name:"maskCnpj",rawName:"v-maskCnpj",value:(_vm.maskCnpj),expression:"maskCnpj"},{name:"maskCpf",rawName:"v-maskCpf",value:(_vm.maskCpf),expression:"maskCpf"},{name:"maskHibrid",rawName:"v-maskHibrid",value:(_vm.maskHibrid),expression:"maskHibrid"},{name:"maskCurrency",rawName:"v-maskCurrency",value:(_vm.maskCurrency),expression:"maskCurrency"},{name:"maskCep",rawName:"v-maskCep",value:(_vm.maskCep),expression:"maskCep"},{name:"maskPhone",rawName:"v-maskPhone",value:(_vm.maskPhone),expression:"maskPhone"}],class:{
        'jsm-input__input': _vm.styleType === 'material',
        'jsm-input__input--nude': _vm.styleType === 'nude',
        'jsm-input__input--small': _vm.size === 'small',
        'jsm-input__input--form-control': _vm.styleType === 'form-control',
        'jsm-input__input--with-prefix': _vm.prefix,
        'jsm-input__input--with-suffix': _vm.suffix,
        'jsm-input__input--danger': _vm.withError,
        'jsm-input__input--select-behavior': _vm.selectBehavior,
      },style:({ paddingRight: _vm.paddingRight }),attrs:{"name":_vm.name,"id":_vm.id,"type":_vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled || _vm.selectBehavior,"maxlength":_vm.maxlength,"minlength":_vm.minlength,"max":_vm.maxDate},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event.target.value)},"keydown":function($event){return _vm.$emit('keydown', $event)},"keypress":function($event){return _vm.$emit('keypress', $event)},"keyup":function($event){return _vm.$emit('keyup', $event)},"click":_vm.handleClick,"change":function($event){return _vm.$emit('change', $event)}}}),_c('label',{class:{
        'jsm-input__label': _vm.styleType === 'material',
        'jsm-input__label--form-control': _vm.styleType === 'form-control',
        'jsm-input__label--with-prefix': _vm.prefix,
        'jsm-input__label--with-suffix': _vm.suffix,
        'jsm-input__label--fix-alignment': _vm.labelAlignment,
      },attrs:{"for":"input"}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"jsm-input-inner-wrapper_suffix",class:{
        'jsm-input-inner-wrapper__suffix--date-type': _vm.type === 'date',
      },on:{"click":function($event){return _vm.$emit('suffixClick')},"mousedown":function($event){return _vm.$emit('suffixMouseDown')},"mouseup":function($event){return _vm.$emit('suffixMouseUp')},"mouseout":function($event){return _vm.$emit('suffixMouseOver')},"touchstart":function($event){return _vm.$emit('suffixTouchStart')},"touchend":function($event){return _vm.$emit('suffixTouchEnd')}}},[(_vm.suffix)?_c(_vm.suffix,{tag:"component",staticClass:"jsm-input-inner-wrapper__suffix",class:{
          'jsm-input-inner-wrapper__suffix--small': _vm.size === 'small',
        },style:(Object.assign({}, {maxWidth: _vm.suffixMaxWidth,
          fill: _vm.suffixColor},
          _vm.suffixStyle))}):_vm._e()],1)],1),(_vm.errorMessage)?_c('p',{staticClass:"jsm-input__error-message",class:("jsm-input__error-message--" + _vm.name)},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }