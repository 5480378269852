<template>
  <div class="responsive-navbar-wrapper">
    <img
      class="responsive-navbar__logo-img"
      alt="Juntos Somos Mais"
      src="@/assets/images/logo_jsm.png"
    />
    <main-left-nav />

    <span @click="logout" class="responsive-navbar__logout">Sair</span>
  </div>
</template>

<script>
import MainLeftNav from '../main-left-nav/MainLeftNav.vue'
export default {
  name: 'ResponsiveNavBar',
  components: {
    MainLeftNav,
  },
  methods: {
    goToAccessAs() {
      this.$router.push({ path: '/access/list', name: 'ListAccessAs' })
    },
    logout() {
      this.$persistence.clear()
      this.$router.push({ name: 'Login' })
      this.$checkUserRoles()
    },
  },
}
</script>

<style src="./ResponsiveNavBar.scss" lang="scss" />
