import Vue from 'vue'

import axios from 'axios'
import Qs from 'qs'

import { storageKeys } from '@/enums/storageKeys'

import getSessionId from '../helpers/GetSessionId'

import storage from './localstorage.service'

const tokenId = storage.get(storageKeys.TOKEN) || ''
const token = storage.get(process.env.VUE_APP_TEMPORARY_TOKEN) ?? tokenId
const config = {
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }), //remove [] and allow at query params that repeat
  baseURL: process.env.VUE_APP_ENVIRONMENT,
  sessionid: getSessionId(),
  withCredentials: true,
}
const DEFAULT_ERROR =
  'Nos desculpe, mas ocorreu algum erro. Por favor, tente novamente.'

const authenticated = axios.create(config)

authenticated.interceptors.request.use((config) => {
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

const responseOnError = (error) => {
  if (!error?.response?.data?.errors) {
    Vue.notification.error(DEFAULT_ERROR)
  }

  return Promise.reject(error)
}

const setInstanceResponseInterceptors = (instance) => {
  instance.interceptors.response.use((response) => response, responseOnError)
}

;[authenticated].forEach(setInstanceResponseInterceptors)

export default {
  authenticated,
}
