const Carousels = () =>
  import(
    /* webpackChunkName: "carousels" */ '../../views/carousels/Carousels.vue'
  )
const ListCarousels = () =>
  import(
    /* webpackChunkName: "carousels" */ '../../views/carousels/components/list-carousels/ListCarousels.vue'
  )
const OrderCarousels = () =>
  import(
    /* webpackChunkName: "carousels" */ '../../views/carousels/components/order-carousels/OrderCarousels.vue'
  )
const EditCarousel = () =>
  import(
    /* webpackChunkName: "carousels" */ '../../views/carousels/components/edit-carousel/EditCarousel.vue'
  )

export default {
  path: 'carousels',
  component: Carousels,
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'ListCarousels',
      component: ListCarousels,
      meta: {
        breadcrumb: [
          { name: 'Regras e definições', pathName: '' },
          { name: 'Vitrine', pathName: 'ListCarousels' },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'CADASTRAR VITRINE',
            to: 'NewCarousel',
            tabTitle: 'CADASTRAR VITRINE',
          },
          {
            type: 'nude-primary',
            text: 'ORDENAR',
            to: 'OrderCarousels',
            tabTitle: 'ORDENAR VITRINES',
          },
        ],
        claims: ['ADisplayAdmin.All'],
      },
    },
    {
      path: 'new',
      name: 'NewCarousel',
      component: EditCarousel,
      meta: {
        breadcrumb: [
          { name: 'Regras e definições', pathName: '' },
          { name: 'Vitrines', pathName: 'ListCarousels' },
          { name: 'Nova Vitrine', pathName: 'NewCarousel' },
        ],
        claims: ['ADisplayAdmin.All'],
      },
    },
    {
      path: 'order',
      name: 'OrderCarousels',
      component: OrderCarousels,
      meta: {
        breadcrumb: [
          { name: 'Regras e definições', pathName: '' },
          { name: 'Vitrines', pathName: 'ListCarousels' },
          { name: 'Ordenar Vitrines', pathName: 'OrderCarousels' },
        ],
        claims: ['ADisplayAdmin.All'],
      },
    },
  ],
}
