import { render, staticRenderFns } from "./JsmButton.vue?vue&type=template&id=5f1f7314&scoped=true&"
import script from "./JsmButton.vue?vue&type=script&lang=js&"
export * from "./JsmButton.vue?vue&type=script&lang=js&"
import style0 from "./JsmButton.styl?vue&type=style&index=0&id=5f1f7314&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f1f7314",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
