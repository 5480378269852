const DistributionCenter = () =>
  import(
    /* webpackChunkName: "distribution" */ '@/views/distribution-center/DistributionCenter.vue'
  )
const ListDistributionCenter = () =>
  import(
    /* webpackChunkName: "distribution" */ '@/views/distribution-center/components/list-distribution-center/ListDistributionCenter.vue'
  )
const NewDistributionCenter = () =>
  import(
    /* webpackChunkName: "distribution" */ '@/views/distribution-center/components/form-distribution-center/FormDistributionCenter.vue'
  )

export default {
  path: '/distribution-center',
  name: 'DistributionCenter',
  component: DistributionCenter,
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'ListDistributionCenter',
      component: ListDistributionCenter,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          {
            name: 'Centro de Distribuição',
            pathName: 'ListDistributionCenter',
          },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'NOVO CENTRO DE DISTRIBUIÇÃO',
            to: 'NewDistributionCenter',
            tabTitle: 'NOVO CD',
          },
        ],
        claims: ['SellerAdmin.All', 'UserAdmin.All'],
      },
    },
    {
      path: 'new',
      name: 'NewDistributionCenter',
      component: NewDistributionCenter,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          {
            name: 'Centro de Distribuição',
            pathName: 'ListDistributionCenter',
          },
          {
            name: 'Novo Centro de Distribuição',
            pathName: 'NewDistributionCenter',
          },
        ],
        claims: ['SellerAdmin.All', 'UserAdmin.All'],
      },
    },
  ],
}
