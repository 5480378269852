import { load } from 'recaptcha-v3'

let singletonInstance = null
const RECAPTCHA_ACTION = 'resetPassword'
class RecaptchaV3 {
  constructor() {
    if (!singletonInstance) {
      singletonInstance = this
    }
    singletonInstance.recaptcha = null
    return singletonInstance
  }

  async load() {
    this.recaptcha = await load(process.env.VUE_APP_RECAPTCHA_V3_API_KEY, {
      autoHideBadge: true,
    })
  }

  async getPasswordResetToken() {
    return this.recaptcha.execute(RECAPTCHA_ACTION)
  }
}
const recaptchaV3 = new RecaptchaV3()

export default recaptchaV3
