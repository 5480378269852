const ForgotPassword = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '../../views/forgot-password/ForgotPassword.vue'
  )
const EmailForm = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '../../views/forgot-password/components/email-form/EmailForm.vue'
  )
const RegistryFound = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '../../views/forgot-password/components/registry-found/RegistryFound.vue'
  )
const RegistryNotFound = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '../../views/forgot-password/components/registry-not-found/RegistryNotFound.vue'
  )
const ChangePassword = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '../../views/forgot-password/components/change-password/ChangePassword.vue'
  )
const PasswordChanged = () =>
  import(
    /* webpackChunkName: "forgot-password" */ '../../views/forgot-password/components/password-changed/PasswordChanged.vue'
  )

export default {
  path: '/forgot-password',
  name: '',
  component: ForgotPassword,
  children: [
    {
      path: '',
      name: 'RequestPassword',
      component: EmailForm,
    },
    {
      path: 'registry-found',
      name: 'RegistryFound',
      component: RegistryFound,
    },
    {
      path: 'registry-not-found',
      name: 'RegistryNotFound',
      component: RegistryNotFound,
    },
    {
      path: 'change-password',
      name: 'ChangePassword',
      component: ChangePassword,
      props: (route) => ({ code: route.query.code }),
      beforeEnter: (to, _, next) => {
        if (!to.query.code) {
          next('')
        } else {
          next()
        }
      },
    },
    {
      path: 'password-changed',
      name: 'PasswordChanged',
      component: PasswordChanged,
    },
  ],
}
