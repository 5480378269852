import Vue from 'vue'

import Vuex from 'vuex'

import breadcrumb from './modules/breadcrumb'
import product from './modules/product'
import tabSystem from './modules/tab-system/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    tabSystem,
    breadcrumb,
    product,
  },
})
