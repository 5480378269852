var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-left-nav-wrapper"},[_vm._l((_vm.items),function(item,idx){return _c('div',{key:item.name,staticClass:"main-left-nav__item",class:[
      {
        'main-left-nav__item--show': _vm.hasRoles(item),
        'main-left-nav__item--last': _vm.isLast(idx),
        'main-left-nav__item--opened': item.isOpened,
      } ],on:{"click":function($event){return _vm.openItem(item)}}},[_c('div',[_c(item.icon,{tag:"component"}),_c('p',{staticClass:"main-left-nav__item-name"},[_vm._v(_vm._s(item.name))]),(item.showChildren)?_c('div',{staticClass:"main-left-nav__item-child-menu"},[(item.title)?_c('div',{staticClass:"main-left-nav__item-title-wrapper"},[_c('p',{staticClass:"main-left-nav__item-title"},[_vm._v(_vm._s(item.title))])]):_vm._e(),(!item.command)?_c('div',_vm._l((item.children),function(child){return _c('div',{key:child.name,class:{
              'main-left-nav__item-child-menu--show': _vm.hasRoles(child),
            }},[(_vm.hasRoles(child))?_c('div',{staticClass:"main-left-nav__item-child-menu-item",on:{"click":function($event){$event.stopPropagation();return _vm.goTo(child.pathName)}}},[_c('p',{staticClass:"main-left-nav_item-child-menu-item-name"},[_vm._v(" "+_vm._s(child.name)+" ")])]):_vm._e()])}),0):_vm._l((item.children),function(child){return _c('div',{key:child.name,staticClass:"main-left-nav__item-child-menu-item",on:{"click":function($event){return _vm.handleCommand(child.command)}}},[_c('p',{staticClass:"main-left-nav_item-child-menu-item-name"},[_vm._v(" "+_vm._s(child.name)+" ")])])})],2):_vm._e()],1)])}),(_vm.isBackdropOn)?_c('div',{staticClass:"main-left-nav__backdrop",on:{"click":function($event){$event.stopPropagation();return _vm.closeItems()}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }