/* eslint-disable vue/no-side-effects-in-computed-properties */
<template>
  <div class="jsm-table-outer__wrapper" :style="{ marginBottom }">
    <div class="jsm-table__search-field-and-filter-wrapper">
      <jsm-button
        v-if="showRemoveSelection && selectable"
        class="jsm-table__remove-selection-button"
        :disabled="!selectedItems.length"
        text="Remover selecionados"
        type="danger"
        @click="removeSelectedItems"
      />

      <div v-if="countRegisters" class="jsm-table__registers-counted-wrapper">
        <span class="jsm-table__registers-counted">{{ data.length }}</span>
        &nbsp;
        <p>REGISTROS ENCONTRADOS</p>
      </div>

      <jsm-select
        v-if="showSelectSearch && selectOptionsWithPrefix"
        class="jsm-table__select-filter"
        :optionsWithPrefix="searchSelectOptions"
        :placeholder="searchSelectPlaceholder"
        :label="searchSelectLabel"
        @change="changeSelected"
        :returnObjectInstead="true"
      />

      <jsm-select
        v-if="showSelectSearch && !selectOptionsWithPrefix"
        class="jsm-table__select-filter"
        :options="searchSelectOptions"
        :placeholder="searchSelectPlaceholder"
        :label="searchSelectLabel"
        @change="changeSelected"
        :returnObjectInstead="true"
      />

      <div class="jsm-table__search-container">
        <jsm-input
          v-if="!countRegisters && showSearchInput"
          class="jsm-table__search-input"
          :placeholder="placeholderSearchInput"
          :label="placeholderSearchInput"
          :suffix="searchIcon"
          suffixMaxWidth="20px"
          suffixColor="#BABABA"
          v-model="searchInput"
          @keyup.enter="validSearch"
        />

        <jsm-button
          v-if="searchButton"
          class="jsm-table__search-button"
          v-model="searchButton"
          type="nude"
          text="Pesquisar"
          @click="search"
        />
      </div>
      <div
        v-if="showRegistersPerPage && !searchModalLayout"
        class="jsm-table__filter-inner-wrapper"
      >
        <jsm-select
          v-if="!serverSidePagination"
          class="jsm-table__select-register-per-page"
          styleType="form-control"
          @change="setShowAmount"
          :options="options"
          :defaultValue="'5'"
        />
        <jsm-select
          v-if="serverSidePagination"
          class="jsm-table__select-register-per-page"
          styleType="form-control"
          @change="getByShowAmount"
          :options="options"
          :defaultValue="'5'"
        />
        <p class="jsm-table__register-per-page-paragraph">
          REGISTROS POR PÁGINA
        </p>
      </div>
    </div>

    <div class="jsm-table-wrapper">
      <div v-if="showColumnNames" class="jsm-table__columns">
        <p
          v-if="selectable"
          class="jsm-table__column jsm-table__column--checkable"
        >
          <span v-if="!selectAsRadio">
            <jsm-checkbox
              class="jsm-checkable"
              @change="selectAllItems()"
              :checked="hasSelection"
            />
          </span>
        </p>
        <div
          class="jsm-table__column"
          v-for="column in columns"
          :key="column.label"
          :class="'jsm-table__title-column__' + column.name"
          @click="sortBy(column.name)"
        >
          <div class="jsm-table__column__container">
            <span
              class="jsm-table__column__title"
              :title="column.label"
              v-text="column.label"
            />
            <span
              v-if="showOrderBy"
              class="arrow"
              :class="sortOrders[column.name] > 0 ? 'asc' : 'dsc'"
            />
          </div>
        </div>
      </div>
      <tbody v-for="(field, index) in filteredData" :key="index">
        <tr
          :class="[
            'jsm-table__row',
            { 'jsm-table__row--striped': type === 'striped' && !isOdd(index) },
            { 'jsm-table__row--alternative': type === 'alternative' },
            { 'jsm-table__row--toggable': hasDefaultSlot },
            {
              'jsm-table__row--toggled':
                hasDefaultSlot && showingItems.includes(field._ix),
            },
            { 'jsm-table__row--dotted': type === 'dotted' },
          ]"
        >
          <div v-if="selectable" class="jsm-table__data">
            <div class="jsm-table__data-check">
              <jsm-checkbox
                @change="setSelectIndex(field._ix)"
                :checked="selectedIndex[field._ix]"
                class="jsm-checkable"
                :uncheckableWhenChecked="selectAsRadio"
              />
            </div>
          </div>

          <td
            class="jsm-table__data"
            v-for="(key, index) in columns"
            :key="index"
            :style="key.customStyle"
            @click.prevent.stop="handleClick(field, key, filteredData)"
            :class="'jsm-table__data-column__' + key.name"
          >
            <div
              v-if="key.prefixImage"
              class="jsm-table__data-field"
              v-html="field[key.name]"
            ></div>
            <div v-else-if="key.slot">
              <slot :field="field" name="extra-component" />
              <slot :field="field" :name="key.name" v-if="field[key.name]" />
            </div>

            <div v-else-if="key.actionButtons">
              <div
                class="jsm-table__action-buttons-wrapper"
                v-if="showButtonsWrapper"
              >
                <div
                  class="jsm-table__action-button"
                  @click="$emit(button.action, field)"
                  v-for="button in field.actions"
                  :key="button.id"
                >
                  <img
                    v-if="button.icon"
                    :src="require(`../assets/images/${button.icon}.png`)"
                  />
                </div>
              </div>

              <div
                v-else
                class="jsm-table__dot-menu"
                @click="showMobileActionMenu(key, field)"
              >
                <div class="jsm-table__dots-wrapper">
                  <div class="jsm-table__dot-menu-dot" />
                  <div class="jsm-table__dot-menu-dot" />
                  <div class="jsm-table__dot-menu-dot" />
                </div>
                <div
                  v-if="key.showActionMenu && field.showActionMenu"
                  class="jsm-table__dot-menu-options"
                >
                  <div
                    @click="handleActionButtonClick(button, field)"
                    v-for="button in field.actions"
                    :key="button.id"
                    class="jsm-table__dot-menu-option"
                  >
                    <div class="jsm-table__dot-menu-option-with-check">
                      <img
                        v-if="button.icon"
                        :src="require(`../assets/images/${button.icon}.png`)"
                      />
                      <p>{{ button.text }}</p>
                    </div>
                    <div v-if="button.checkable" style="margin-left: auto">
                      <jsm-checkbox
                        class="jsm-checkable"
                        :checked="button.checkedAction"
                      />
                    </div>
                  </div>
                  <div v-if="showExportButton">
                    <jsm-button
                      type="nude-warning"
                      text="EXPORTAR"
                      @click="exportDocs(key, field)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <p class="jsm-table__data-field" v-else :title="field[key.name]">
              <span v-if="$mob" v-html="field[key.name]" />
              <span v-else>{{ field[key.name] | cropWords(20) }}</span>
            </p>
            <!--
            <p
              class="jsm-table__data-field"
              v-else
              :title="field[key.name]"
              v-html="field[key.name]"
            />-->
          </td>

          <td
            v-if="hasDefaultSlot && !hideToggleButton"
            :class="[
              'jsm-table__toggle-btn',
              {
                'jsm-table__toggle-btn--toggled':
                  hasDefaultSlot && showingItems.includes(field._ix),
              },
            ]"
            @click="toggleButtonClick(field, filteredData)"
          />
          <td v-if="closable" class="jsm-table__data">
            <div class="jsm-table__close-icon-wrapper">
              <close-icon
                @click="removeItem(field)"
                class="jsm-table__close-icon"
              />
            </div>
          </td>
        </tr>

        <tr v-if="type === 'alternative'" class="jsm-table__spacer" />
        <tr
          class="jsm-table__slot"
          :colspan="20"
          v-if="hasDefaultSlot && showingItems.includes(field._ix)"
        >
          <td :colspan="20">
            <slot />
          </td>
          <td
            v-if="closable && !hideClosableOnChildren"
            class="jsm-table__data"
          >
            <div class="jsm-table__close-icon-wrapper">
              <close-icon @click="removeItem" class="jsm-table__close-icon" />
            </div>
          </td>
        </tr>
      </tbody>
      <div
        v-if="showRegistersPerPage && searchModalLayout"
        class="jsm-table__modal-layout__footer"
      >
        <jsm-select
          v-if="!serverSidePagination"
          class="jsm-table__select-register-per-page"
          styleType="form-control"
          @change="setShowAmount"
          :options="options"
          :defaultValue="'5'"
        />
        <jsm-select
          v-if="serverSidePagination"
          class="jsm-table__select-register-per-page"
          styleType="form-control"
          @change="getByShowAmount"
          :options="options"
          :defaultValue="'5'"
        />
        <div v-if="showPagination" class="jsm-table__pagination">
          <div v-if="!serverSidePagination">
            <jsm-table-pagination
              class="jsm-table__pagination-box"
              @pageSelected="setPage"
              :items="data"
              :showAmount="showAmount"
            />
          </div>
          <div
            class="jsm-table__pagination-box"
            v-if="showPagination && serverSidePagination"
          >
            <jsm-table-pagination
              @pageSelected="setPageOnline"
              :items="data"
              :showAmount="showAmount"
              :totalPages="totalPages"
              :serverSidePagination="serverSidePagination"
            />
          </div>
        </div>
      </div>

      <div
        v-if="showPagination && !searchModalLayout"
        class="jsm-table__modal-layout__footer"
      >
        <div class="jsm-table__modal-container" v-if="!serverSidePagination">
          <jsm-table-pagination
            @pageSelected="setPage"
            :items="data"
            :showAmount="showAmount"
          />
        </div>
        <div
          class="jsm-table__modal-container"
          v-if="showPagination && serverSidePagination"
        >
          <jsm-table-pagination
            @pageSelected="setPageOnline"
            :selectedPage="selectedPage"
            :items="data"
            :showAmount="showAmount"
            :totalPages="totalPages"
            :serverSidePagination="serverSidePagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hash from 'object-hash'

import JsmButton from '../../jsm-button/src/JsmButton.vue'
import JsmCheckbox from '../../jsm-checkbox/src/JsmCheckbox.vue'
import JsmInput from '../../jsm-input/src/JsmInput.vue'
import JsmSelect from '../../jsm-select/src/JsmSelect.vue'
import CloseIcon from '../assets/images/close.svg'
import SearchIcon from '../assets/images/search.svg'
import JsmTablePagination from '../components/jsm-table-pagination/JsmTablePagination.vue'

import '../helpers/prototypes'

export default {
  name: 'JsmTable',

  components: {
    JsmTablePagination,
    JsmInput,
    JsmSelect,
    JsmCheckbox,
    JsmButton,
    CloseIcon,
  },

  props: {
    columns: { type: Array, default: () => [], required: true },
    closable: { type: Boolean, default: false },
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    showSelectSearch: { type: Boolean, default: false },
    showRegistersPerPage: { type: Boolean, default: true },
    showSearchInput: { type: Boolean, default: true },
    showExportButton: { type: Boolean, default: false },
    showPagination: { type: Boolean, default: true },
    doubleSearch: { type: Boolean, default: false },
    searchSelectOptions: { type: Array, default: () => [] },
    type: {
      type: String,
      default: 'default',
      validator: (value) =>
        ['default', 'striped', 'alternative', 'dotted'].indexOf(value) !== -1,
    },
    searchSelectPlaceholder: { type: String, default: '' },
    searchSelectLabel: { type: String, default: '' },
    countRegisters: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    showRemoveSelection: { type: Boolean, default: false },
    selectOnRowClick: { type: Boolean, default: false },
    serverSidePagination: { type: Boolean, default: false },
    totalPages: { type: Number, default: 10 },
    searchButton: { type: Boolean, default: false },
    filteredForSearchInput: { type: Boolean, default: true },
    searchModalLayout: { type: Boolean, default: false },
    selectOptionsWithPrefix: { type: Boolean, default: false },
    showOrderBy: { type: Boolean, default: true },
    marginBottom: { type: String, default: '120px' },
    selectAsRadio: { type: Boolean, default: false },
    showColumnNames: { type: Boolean, default: true },
    showButtonsWrapper: { type: Boolean, default: false },
    showOneChildPerClick: { type: Boolean, default: false },
    hideToggleButton: { type: Boolean, default: false },
    hideClosableOnChildren: { type: Boolean, default: false },
    placeholderSearchInput: { type: String, default: 'PESQUISAR' },
    selectedPage: { type: Number },
  },

  filters: {
    cropWords(value, maxLength) {
      if (!value) return ''

      const valueHandled = String(value)

      if (valueHandled.length <= maxLength) return valueHandled

      return `${valueHandled.split('').slice(0, maxLength).join('')}...`
    },
  },

  data() {
    const sortOrders = {}

    this.columns.forEach((key) => {
      sortOrders[key.name] = 1
    })

    return {
      sortKey: '',
      sortOrders,
      searchIcon: SearchIcon,
      searchInput: '',
      showAmount: 5,
      page: 0,
      startSplice: 0,
      options: ['5', '10', '15'],
      selectedItems: [],
      selectedIndex: {},
      hasSelection: false,
      checkedAction: false,
      visibleAction: false,
      copyData: [],
      showingItems: [],
    }
  },

  computed: {
    filteredData: {
      get() {
        var filterKey = null
        if (this.filteredForSearchInput) {
          filterKey = this.searchInput && this.searchInput.toLowerCase()
        }

        const order = this.sortOrders[this.sortKey] || 1

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.copyData = Array.prototype.clone(this.data || []).map((item) => {
          item._ix = hash(item)

          return item
        })

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (!this.showPagination) this.showAmount = this.copyData.length

        if (filterKey) {
          return this.copyData
            .filter((row) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              Object.keys(row).some(
                (key) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  String(row[key]).toLowerCase().indexOf(filterKey) > -1
              )
            )
            .splice(this.startSplice, this.showAmount)
        }

        if (this.sortKey) {
          return this.copyData
            .slice()
            .sort((a, b) => {
              // eslint-disable-next-line no-param-reassign
              a = a[this.sortKey]
              // eslint-disable-next-line no-param-reassign
              b = b[this.sortKey]
              // eslint-disable-next-line no-nested-ternary
              return (a === b ? 0 : a > b ? 1 : -1) * order
            })
            .splice(this.startSplice, this.showAmount)
        }

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const spliced = this.copyData.splice(this.startSplice, this.showAmount)

        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.copyData = spliced

        return spliced
      },

      set() {
        var filterKey = null
        if (this.filteredForSearchInput) {
          filterKey = this.searchInput && this.searchInput.toLowerCase()
        }

        const order = this.sortOrders[this.sortKey] || 1
        this.copyData = Array.prototype.clone(this.data || []).map((item) => {
          item._ix = hash(item)

          return item
        })

        if (!this.showPagination) this.showAmount = this.copyData.length

        if (filterKey) {
          return this.copyData
            .filter((row) =>
              // eslint-disable-next-line implicit-arrow-linebreak
              Object.keys(row).some(
                (key) =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  String(row[key]).toLowerCase().indexOf(filterKey) > -1
              )
            )
            .splice(this.startSplice, this.showAmount)
        }

        if (this.sortKey) {
          return this.copyData
            .slice()
            .sort((a, b) => {
              // eslint-disable-next-line no-param-reassign
              a = a[this.sortKey]
              // eslint-disable-next-line no-param-reassign
              b = b[this.sortKey]
              // eslint-disable-next-line no-nested-ternary
              return (a === b ? 0 : a > b ? 1 : -1) * order
            })
            .splice(this.startSplice, this.showAmount)
        }

        const spliced = this.copyData.splice(this.startSplice, this.showAmount)

        this.copyData = spliced

        return spliced
      },
    },

    hasDefaultSlot() {
      return !!this.$slots.default
    },
  },

  watch: {
    data(data) {
      const allAreadySelected = data.every((item) =>
        this.selectedItems.some((selectedItem) => selectedItem.id === item.id)
      )

      if (allAreadySelected) {
        this.hasSelection = true
      } else {
        this.hasSelection = false
      }

      this.copyData = Array.prototype.clone(data).map((item) => {
        item._ix = hash(item)

        return item
      })
    },
  },

  mounted() {
    window.addEventListener('click', () => this.hideAllActionButtons())
  },

  methods: {
    removeItem(field) {
      const idx = this.copyData.findIndex((item) => item._ix === field._ix)
      const removedItem = this.copyData.splice(idx, 1)
      this.$emit('removeItem', removedItem)
    },

    validSearch() {
      if (!this.filteredForSearchInput) {
        return this.search()
      } else {
        return () => {}
      }
    },

    hideAllActionButtons() {
      this.filteredData.forEach((i) => (i.showActionMenu = false))

      this.$forceUpdate()
    },

    changeSelected(item) {
      if (this.selectOptionsWithPrefix === true) {
        if (item.text == 'Todos') {
          if (this.doubleSearch) {
            this.$emit('secondSearch', item)
          } else {
            this.searchInput = ''
          }
        } else {
          if (this.doubleSearch) {
            this.$emit('secondSearch', item)
          } else {
            this.searchInput = item.text
          }
        }
      } else {
        if (item == 'Todos') {
          this.searchInput = ''
          this.$emit('secondSearch', item)
        } else {
          //this.searchInput = item;
          this.$emit('secondSearch', item)
        }
      }
    },

    handleActionButtonClick(button, field) {
      if (!button.checkable) {
        this.$emit(button.action, field)
      } else {
        this.checkAction(button, field)
      }
    },

    exportDocs(column, field) {
      field.showActionMenu = false
      column.showActionMenu = false
      this.$forceUpdate()
      this.$emit('exportDocs', field)
    },

    showMobileActionMenu(column, field) {
      if (field.actions.some((f) => f.checkable === true)) {
        field.showActionMenu = true
        column.showActionMenu = true
        this.$forceUpdate()
        return
      }

      this.filteredData.forEach((i) => (i.showActionMenu = false))
      field.showActionMenu = !field.showActionMenu
      column.showActionMenu = !column.showActionMenu
      this.$forceUpdate()
    },

    toggleButtonClick(fieldSent) {
      const field = fieldSent
      this.expandRow(field)
      if (this.selectOnRowClick) this.setSelectIndex(field._ix)
      this.$forceUpdate()
    },

    handleClick(fieldSent, column) {
      if (column.actionButtons) return
      const field = fieldSent
      this.expandRow(field)
      if (this.selectOnRowClick) this.setSelectIndex(field._ix)
      this.$forceUpdate()
    },

    expandRow(field) {
      const isOpening = this.showingItems.includes(field._ix) ? false : true

      if (this.showOneChildPerClick)
        this.showingItems.splice(0, this.showingItems.length)

      if (!isOpening) {
        this.showingItems.map((item, idx) => {
          if (item === field._ix) this.showingItems.splice(idx, 1)
        })
      } else {
        this.showingItems.push(field._ix)
      }

      this.$emit('selectedRow', field)
      this.$forceUpdate()
    },

    setPage(page) {
      this.page = page

      this.startSplice = Math.ceil(page * this.showAmount)
      this.endSplice = this.startSplice + this.showAmount

      this.$emit('getByPage', this.page, this.showAmount)
      this.$forceUpdate()
    },

    setPageOnline(page) {
      this.page = page + 1
      this.$emit('getByPage', this.page)

      this.$forceUpdate()
    },

    setShowAmount(value) {
      this.showAmount = Number(value)
    },

    getByShowAmount(value) {
      this.$emit('getByAmount', value)
      this.showAmount = Number(value)
    },

    search() {
      this.$emit('getSearch', this.searchInput.trim())
    },

    sortBy(key) {
      if (!this.showOrderBy) return

      this.sortKey = key

      this.sortOrders[key] = this.sortOrders[key] * -1
    },

    isOdd(num) {
      if (num % 2) {
        return false
      }
      return true
    },

    removeSelectedItems() {
      if (!this.selectedItems.length) return

      this.$emit('removeSelectedItems', this.selectedItems)

      this.clearSelection()
    },

    setSelectIndex(ix) {
      if (this.selectAsRadio) {
        this.selectedIndex[ix] = !this.selectedIndex[ix]

        for (let prop in this.selectedIndex) {
          this.selectedIndex[prop] = false
        }
      }

      this.selectedIndex[ix] = !this.selectedIndex[ix]

      // Firstly we add all current paged result at the moment
      const currentItems = this.filteredData.filter(
        (item) => this.selectedIndex[item._ix]
      )

      this.selectedItems = [...currentItems, ...this.selectedItems]

      // Now we have to filter to be sure we are adding just selected items
      let filteredIds = [...new Set(this.selectedItems.map((i) => i.id))]

      this.selectedItems = filteredIds
        .map((id) => this.selectedItems.filter((item) => item.id === id)[0])
        .filter((item) => this.selectedIndex[item._ix])

      filteredIds = [...new Set(this.selectedItems.map((i) => i.id))]

      this.hasSelection = this.filteredData.every((item) =>
        filteredIds.includes(item.id)
      )

      this.selectedIndex = Object.assign({}, this.selectedIndex)

      this.$emit('selectedItems', this.selectedItems)
    },

    checkAction(button, field) {
      field.actions.forEach((a) => {
        if (a.text === button.text) {
          a.checkedAction = !a.checkedAction
        }
      })

      field.showMobileActionMenu = true
      this.$forceUpdate()
      this.$emit(
        'selectActions',
        field.actions.filter((a) => a.checkable === true)
      )
    },

    selectAllItems() {
      if (!this.filteredData.length) return

      if (this.hasSelection) {
        this.filteredData.forEach(
          (item) => (this.selectedIndex[item._ix] = false)
        )

        this.hasSelection = false
      } else {
        this.selectedItems = [
          ...new Set(this.selectedItems.concat(this.filteredData)),
        ]

        const filteredIds = [...new Set(this.selectedItems.map((i) => i.id))]

        this.selectedItems = filteredIds.map((id) => {
          return this.selectedItems.filter((item) => item.id === id)[0]
        })

        this.selectedItems.forEach(
          (item) => (this.selectedIndex[item._ix] = true)
        )

        this.hasSelection = true
      }

      this.$emit('selectedItems', this.selectedItems)

      this.$forceUpdate()
    },

    clearSelection() {
      this.selectedIndex = {}
      this.selectedItems = []
      this.hasSelection = false

      this.$emit('selectedItems', this.selectedItems)

      this.$forceUpdate()
    },
  },
}
</script>

<style lang="stylus" src="./JsmTable.styl" />
