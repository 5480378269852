<template>
  <div
    :style="{
      height: height !== '' ? height : 'fit-content',
      padding,
    }"
    class="jsm-card-wrapper"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'JsmCard',

  props: {
    height: { type: String, default: '' },
    padding: { type: String, default: '2.2rem' },
  },
}
</script>

<style src="./JsmCard.styl" lang="stylus" scoped />
