<template>
  <transition name="from-right">
    <div
      v-if="visible"
      :id="id"
      class="jsm-notification__wrapper"
      :class="`jsm-notification__wrapper--${type}`"
    >
      <div
        class="jsm-notification__close"
        @click="close"
        :class="`jsm-notification__close--${type}`"
      ></div>
      <p
        class="jsm-notification__title"
        :class="`jsm-notification__title--${type}`"
      >
        {{ title }}
      </p>
      <p
        class="jsm-notification__message"
        :class="`jsm-notification__message--${type}`"
        v-html="message"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'JsmNotification',

  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    autoclose: { type: Boolean, default: true },
    timer: { type: Number, default: 5000 },
    type: {
      type: String,
      default: 'primary',
      validator: (value) =>
        ['primary', 'error', 'warning', 'success'].indexOf(value) !== -1,
    },
  },

  mounted() {
    if (this.autoclose) {
      setTimeout(() => {
        this.close()
      }, this.timer)
    }
  },

  watch: {
    autoclose(autoclose) {
      if (autoclose) {
        setTimeout(() => {
          this.close()
        }, this.timer)
      }
    },
  },

  data() {
    return {
      id: Math.random(0, 10),
      visible: false,
    }
  },

  methods: {
    close() {
      this.$notification?.close(this)
    },
  },
}
</script>

<style src="./JsmNotification.styl" lang="stylus" scoped />
