import Vue from 'vue'

import { init } from '@sentry/vue'

init({
  Vue: Vue,
  enabled: true,
  logErrors: true,
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
})
