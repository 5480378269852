<template>
  <div :id="id" class="jsm-select__wrapper">
    <div @click.stop="handleDropdown">
      <jsm-input
        class="jsm-select__input"
        :name="name"
        :label="label"
        :placeholder="placeholder"
        :prefix="prefix"
        :suffix="suffix"
        selectBehavior
        :styleType="styleType"
        v-model="value"
        :size="size"
        :withError="withError"
        :errorMessage="errorMessage"
        :paddingRight="paddingRight"
      />
      <slot name="action-icon" />
    </div>
    <transition name="fade">
      <div
        v-if="!$slots.default && showDropdown && options.length > 0"
        class="jsm-select__dropdown"
      >
        <div
          class="jsm-select__options"
          @click="setSelectedValue(option)"
          v-for="(option, index) in options"
          :key="index"
          v-html="option"
        >
          {{ option }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="!$slots.default && showDropdown && optionsWithPrefix.length > 0"
        class="jsm-select__dropdown"
      >
        <div
          class="jsm-select__options"
          @click="setSelectedValue(option)"
          v-for="(option, index) in optionsWithPrefix"
          :key="index"
        >
          <div class="jsm-select__options-img" v-html="option.prefix" />
          {{ option.text }}
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div @click.stop class="jsm-select__options-slot">
        <slot v-if="showDropdown" />
      </div>
    </transition>
  </div>
</template>

<script>
import { stringId } from '../../../helpers/functions'
import JsmInput from '../../jsm-input/src/JsmInput.vue'
import CaretIcon from '../assets/images/caret.svg'

export default {
  name: 'JsmSelect',

  components: {
    JsmInput,
  },

  props: {
    placeholder: { type: String, default: '' },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    prefix: { type: Object, default: () => {} },
    options: { type: Array, default: () => ['Não há dados'] },
    optionsWithPrefix: {
      type: Array,
      // default: () => [{ prefix: '', text: '' }],
      default: () => [],
    },
    returnObjectInstead: { type: Boolean, default: false },
    defaultValue: { type: String, default: '' },
    withError: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    preventDefaultDropdownBehavior: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium'].indexOf(value) !== -1,
    },
    styleType: {
      type: String,
      default: 'material',
      validator: (value) => ['material', 'form-control'].indexOf(value) !== -1,
    },
    paddingRight: { type: String, default: '' },
  },

  data() {
    return {
      suffix: CaretIcon,
      showDropdown: false,
      value: '',
      id: stringId(),
    }
  },

  watch: {
    defaultValue(value) {
      this.value = value
    },
  },

  mounted() {
    window.addEventListener('click', (e) => this.handleDropdown(e))

    this.value = this.defaultValue
  },

  methods: {
    closeDropdown() {
      this.showDropdown = false
    },
    handleDropdown(e) {
      if (
        e.target.closest('.jsm-select__wrapper') &&
        e.target.closest('.jsm-select__wrapper').matches(`#${this.id}`)
      ) {
        this.showDropdown = !this.showDropdown

        window.removeEventListener('click', (e) => this.handleDropdown(e))
      } else {
        this.showDropdown = false
      }
    },

    setSelectedValue(value) {
      this.value = value.text || value

      if (this.returnObjectInstead) return this.$emit('change', value)

      this.$emit('change', this.value)
    },
  },
}
</script>

<style lang="stylus" src="./JsmSelect.styl" scoped />
