<template>
  <div
    class="jsm-checkbox__wrapper"
    :class="{ 'checkbox__input--checked': checked === true }"
  >
    <label
      class="jsm-checkbox__container-label"
      :class="`jsm-checkbox__container-label--${size} jsm-checkbox__container-label--${
        disabled ? 'disabled' : ''
      }`"
    >
      <span v-if="isThumbAndText === true" v-html="text">{{ text }}</span>
      <span v-if="isThumbAndText === false">{{ text }}</span>
      <slot></slot>
      <input
        type="checkbox"
        class="jsm-checkbox__input"
        :value="value"
        :checked="checked"
        :name="name"
        :id="name"
        :readonly="isReadOnly"
        @click.stop="updateValue"
      />
      <span
        class="jsm-checkbox__checkmark"
        :class="`jsm-checkbox__checkmark--${size}`"
      ></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'JsmCheckbox',

  props: {
    text: { type: String, default: '' },
    name: { type: String, default: '' },
    id: { type: String, default: '' },
    checked: { type: Boolean, default: false },
    value: { type: [String, Boolean, Number], default: '' },
    disabled: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'large'].indexOf(value) !== -1,
    },
    stopPropagation: { type: Boolean, default: false },
    uncheckableWhenChecked: { type: Boolean, default: false },
    isThumbAndText: { type: Boolean, default: false },
    isReadOnly: { type: Boolean, default: false },
  },

  methods: {
    updateValue($event) {
      if (this.stopPropagation) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (this.uncheckableWhenChecked) $event.target.checked = true

      this.$emit('change', $event.target.value)
    },
  },
}
</script>

<style src="./JsmCheckbox.styl" lang="stylus" scoped />
