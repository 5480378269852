<template>
  <div class="jsm-table-pagination__wrapper">
    <div
      @click="previousPage"
      :class="{
        'jsm-table-pagination__previous-button--inactive':
          pages[0] && pages[0].active === true,
      }"
      class="jsm-table-pagination__item previous-button"
    >
      <p>Anterior</p>
    </div>

    <div class="jsm-table-pagination__container" v-if="pages.length < 7">
      <div
        class="jsm-table-pagination__item"
        :class="{ 'jsm-table-pagination__item--active': page.active }"
        v-for="(page, index) in pages"
        :key="index"
        @click="setPage(page)"
      >
        {{ page.index + 1 }}
      </div>
    </div>
    <div class="jsm-table-pagination__container" v-if="pages.length >= 7">
      <div v-if="firsts" class="jsm-table-pagination__container">
        <div
          class="jsm-table-pagination__item"
          :class="{ 'jsm-table-pagination__item--active': page.active }"
          v-for="(page, index) in firsts"
          :key="index"
          @click="setPage(page)"
        >
          {{ page.index + 1 }}
        </div>
      </div>

      <div class="jsm-table-pagination__container" v-if="ativePage >= 4">
        <span class="jsm-table-pagination__item">...</span>
      </div>
      <div
        class="jsm-table-pagination__container"
        v-if="ativePage >= 3 && ativePage < pages.length - 3"
      >
        <span
          class="jsm-table-pagination__item jsm-table-pagination__item--active"
          >{{ ativePage + 1 }}</span
        >
      </div>
      <div
        class="jsm-table-pagination__container"
        v-if="ativePage < pages.length - 3"
      >
        <span class="jsm-table-pagination__item">...</span>
      </div>

      <div v-if="lasts" class="jsm-table-pagination__container">
        <div
          class="jsm-table-pagination__item"
          :class="{ 'jsm-table-pagination__item--active': page.active }"
          v-for="(page, index) in lasts"
          :key="index"
          @click="setPage(page)"
        >
          {{ page.index + 1 }}
        </div>
      </div>
    </div>

    <div
      @click="nextPage"
      class="jsm-table-pagination__item next-button"
      :class="{
        'jsm-table-pagination__next-button--inactive':
          !pages.length ||
          (pages[pages.length - 1] && pages[pages.length - 1].active),
      }"
    >
      <p>Próximo</p>
    </div>
    <div
      @click="lastPage"
      class="jsm-table-pagination__item next-button"
      :class="{
        'jsm-table-pagination__next-button--inactive':
          !pages.length ||
          (pages[pages.length - 1] && pages[pages.length - 1].active),
      }"
    >
      <p>Última</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
    showAmount: { type: Number, default: 5 },
    totalPages: { type: Number, default: 10 },
    serverSidePagination: { type: Boolean },
    isFirstPage: { type: Boolean },
    selectedPage: { type: Number },
  },

  data() {
    return {
      pages: [],
      ativePage: '',
      firsts: [],
      lasts: [],
    }
  },

  mounted() {
    if (this.serverSidePagination) {
      const pagesQuantity = this.totalPages
      this.setPageQuantity(pagesQuantity)
    } else {
      const pagesQuantity = Math.ceil(this.items.length / this.showAmount)
      this.setPageQuantity(pagesQuantity)
    }
    this.ativePage = 0
  },

  beforeUpdate() {
    this.lasts = this.pages.slice(-3)
    this.firsts = this.pages.slice(0, 3)
  },

  watch: {
    showAmount() {
      const amount = Math.ceil(this.items.length / this.showAmount)
      this.setPageQuantity(amount)
    },

    items() {
      if (this.serverSidePagination) {
        const pagesQuantity = this.totalPages
        this.setPageQuantity(pagesQuantity)
      } else {
        const pagesQuantity = Math.ceil(this.items.length / this.showAmount)
        this.setPageQuantity(pagesQuantity)
      }
    },
  },

  methods: {
    setPage(page) {
      this.$emit('pageSelected', page.index)
      this.pages = this.pages.map((i) => {
        const item = i
        if (item === page) {
          item.active = true
          this.ativePage = item.index
        } else {
          item.active = false
        }
        return item
      })
    },

    setPageOnline(page) {
      this.pages = this.pages.map((i) => {
        const item = i
        if (item === page) {
          item.active = true
          this.ativePage = item.index
        } else {
          item.active = false
        }
        return item
      })
    },

    lastPage() {
      const last = this.pages[this.pages.length - 1]
      this.pages = this.pages.map((i) => {
        const item = i
        if (item === last) {
          item.active = true
          this.$emit('pageSelected', last.index)
          this.ativePage = last.index
        } else {
          item.active = false
        }
        return item
      })
    },

    previousPage() {
      for (let i = 0; i < this.pages.length; i += 1) {
        const page = this.pages[i]
        const nextPage = this.pages[i + 1]

        if (nextPage?.active) {
          nextPage.active = false
          page.active = true
          this.ativePage = page.index
          this.$emit('pageSelected', page.index)
          break
        }
      }
    },

    nextPage() {
      for (let i = 0; i < this.pages.length; i += 1) {
        const page = this.pages[i]
        const previousPage = this.pages[i - 1]

        if (previousPage?.active) {
          previousPage.active = false
          page.active = true
          this.ativePage = page.index
          this.$emit('pageSelected', page.index)
          break
        }
      }
    },

    setPageQuantity(amount) {
      this.pages = []
      let page
      if (this.selectedPage) {
        this.ativePage = this.selectedPage - 1
      }

      for (let i = 0; i < amount; i += 1) {
        page = {
          index: i,
          active: i === this.ativePage,
        }
        if (this.ativePage > amount) {
          this.ativePage = 0
        }
        this.pages.push(page)
      }
      if (this.isFirstPage) this.ativePage = 0
    },
  },
}
</script>

<style src="./JsmTablePagination.styl" lang="stylus" />
