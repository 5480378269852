<template>
  <transition :name="transition">
    <div
      id="jsm-loading-wrapper"
      v-if="visible && type === 'normal'"
      class="jsm-loading-wrapper"
    >
      <component class="jsm-loading__spinner" :is="spinner"></component>
      <p
        :style="{
          color,
          fontSize,
          fontFamily,
        }"
      >
        {{ text }}
      </p>
    </div>
    <div class="jsm-loading-wrapper__small" v-if="visible && type === 'small'">
      <!-- <component :is="smallLoadingIcon"></component> -->
      <div class="lds-css">
        <div style="width: 100%; height: 100%" class="lds-rolling">
          <div></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import getCssVariableValue from '@/helpers/get-css-variable-value'

import JsmLoadingIcon from '../assets/images/icon_jsm.svg'
import SmallLoadingIcon from '../assets/images/loading.svg'

export default {
  name: 'JsmLoading',

  props: {
    text: { type: String, default: 'Carregando...' },
    spinner: { default: () => JsmLoadingIcon },
    fontSize: { type: String, default: '1.5em' },
    fontFamily: { type: String, default: '' },
    color: {
      type: String,
      default: getCssVariableValue('--color-neutral-white'),
    },
    transition: { type: String, default: 'fade' },
    type: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'small'].indexOf(value) !== -1,
    },
    isVisible: { type: Boolean, default: false },
  },

  mounted() {
    if (this.type === 'small' && this.isVisible) {
      this.visible = true
    }
  },

  data() {
    return {
      visible: false,
      smallLoadingIcon: SmallLoadingIcon,
    }
  },
}
</script>

<style src="./JsmLoading.styl" lang="stylus" scoped />
