import * as types from './types'

const actions = {
  [types.ACT_CREATE_NEW_TAB]({ state, commit }, tab) {
    const { name, urlName, info } = tab

    const newTab = {
      id: state[types.STATE_TABS].length,
      text: name,
      active: true,
      urlName,
      info,
    }

    commit(types.MUT_UNSET_TABS)
    commit(types.MUT_SET_TAB, newTab)

    return newTab.id
  },
}

export default actions
