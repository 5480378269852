const install = (Vue) => {
  const reactorComponent = new Vue({
    data: () => ({
      mobile: false,
    }),
  })

  let intervalRef

  function resizeWindow() {
    if (intervalRef) clearTimeout(intervalRef)

    intervalRef = setTimeout(() => {
      reactorComponent.mobile = window.outerWidth <= 800
      intervalRef = undefined
    }, 100)
  }

  Vue.mixin({
    computed: {
      $mob() {
        return reactorComponent.mobile
      },
    },
  })

  window.addEventListener('resize', resizeWindow)

  // Initial reference
  resizeWindow()
}

export default { install }
