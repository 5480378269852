const ListProducts = () =>
  import(
    /* webpackChunkName: "products" */ '../../views/products/components/list-products/ListProducts.vue'
  )
const EditProduct = () =>
  import(
    /* webpackChunkName: "products" */ '../../views/products/components/edit-product/EditProduct.vue'
  )
const Products = () =>
  import(/* webpackChunkName: "products" */ '../../views/products/Products.vue')

export default {
  path: 'products',
  component: Products,
  meta: { authenticate: true },
  children: [
    {
      name: 'ListProducts',
      path: 'list',
      component: ListProducts,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Produtos', pathName: 'ListProducts' },
        ],
        actionButtons: [],
        claims: ['ProductAdmin.All'],
      },
    },
    {
      name: 'EditProduct',
      path: 'edit',
      component: EditProduct,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Produtos', pathName: 'ListProducts' },
          { name: 'Editar Produto', pathName: 'EditProduct' },
        ],
        claims: ['ProductAdmin.All'],
      },
    },
  ],
}
