const ListReports = () => import('@/views/reports/components/ListReports')
const Reports = () => import('@/views/reports/Reports')
const ClientReports = () =>
  import('@/views/reports/components/client-reports/ClientReports')
const CancellationReports = () =>
  import('@/views/reports/components/cancellation-reports/CancellationReports')

export default {
  path: '/reports',
  name: 'reports',
  component: Reports,
  meta: { authenticate: true },
  children: [
    {
      path: 'audit',
      name: 'ListReports',
      component: ListReports,
      meta: {
        breadcrumb: [
          {
            name: 'Relatórios',
            pathName: 'audit',
          },
        ],
      },
    },
    {
      path: 'client',
      name: 'ClientReports',
      component: ClientReports,
      meta: {
        breadcrumb: [
          {
            name: 'Relatórios',
            pathName: 'client',
          },
        ],
        claims: ['UserAdmin.All'],
      },
    },
    {
      path: 'cancellation',
      name: 'CancellationReports',
      component: CancellationReports,
      meta: {
        breadcrumb: [
          {
            name: 'Relatórios',
            pathName: 'CancellationReports',
          },
        ],
        claims: ['UserAdmin.All'],
      },
    },
  ],
}
