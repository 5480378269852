const AccessAs = () =>
  import(
    /* webpackChunkName: "access-as" */ '../../views/user/components/access-as/AccessAs.vue'
  )
const ListAccessAs = () =>
  import(
    /* webpackChunkName: "access-as" */ '../../views/user/components/access-as/components/list-access-as/ListAccessAs.vue'
  )

export default {
  path: 'access',
  component: AccessAs,
  meta: { authenticate: true },
  children: [
    {
      path: 'list',
      name: 'ListAccessAs',
      component: ListAccessAs,
      meta: {
        breadcrumb: [
          { name: 'Regras e Definições', pathName: '' },
          { name: 'Acessar Como', pathName: 'ListAccessAs' },
        ],
        claims: [
          'AccessAsAdmin.All',
          'AccessAsAdmin.Email.Edit',
          'AccessAsAdmin.Infos.Edit',
          'AccessAsAdmin.OwnedOnly',
        ],
      },
    },
  ],
}
