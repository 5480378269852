/* eslint-disable no-return-await */
import { storageKeys } from '@/enums/storageKeys'
import recaptchaV3 from '@/helpers/recaptcha'

import request from './base-request.service'
import service from './base.service'
import localstorage from './localstorage.service'

import { sitesApi } from './'
let accessToken = ''

const IDENTITY_URL = '/identity/v1/'

function loadToken() {
  const tokenFromLocal = localstorage.get(storageKeys.TOKEN)

  if (!tokenFromLocal || typeof tokenFromLocal === 'string') return

  accessToken = tokenFromLocal
}

function getAccessToken() {
  return accessToken
}

async function login(email, password) {
  return await request({
    method: 'POST',
    endpoint: process.env.VUE_APP_IDENTITY_LOGIN,
    body: { email, password, appType: 'AdminApplication' },
  })
}

async function checkUser(args) {
  const user = {
    cnpj: args.cnpj,
    email: args.email,
    redirectUrl: process.env.VUE_APP_REDIRECT_CHANGE_PASSWORD_URL,
  }

  user.token = await recaptchaV3.getPasswordResetToken()

  try {
    let result = await service.authenticated.post(
      process.env.VUE_APP_IDENTITY_RESET_PASSWORD,
      user
    )
    return result.data
  } catch (error) {
    return error
  }
}

async function changePassword({
  email,
  cnpj,
  password,
  passwordConfirmation,
  code,
}) {
  const body = {
    email,
    cnpj,
    password,
    passwordConfirmation,
    code,
  }
  return await request({
    method: 'PUT',
    endpoint: process.env.VUE_APP_IDENTITY_RESET_PASSWORD_V1,
    body,
  })
}

async function sendTokenToUser(token) {
  const result = await request({
    method: 'GET',
    endpoint: process.env.VUE_APP_IDENTITY_MFA,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return result
}

async function getTokenByMFA(token, code) {
  const mfa = {
    code,
  }

  const result = await request({
    method: 'POST',
    body: mfa,
    endpoint: process.env.VUE_APP_IDENTITY_MFA,
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return result
}

function isAuthenticated() {
  const token = localstorage.get(storageKeys.TOKEN)
  if (token) return true

  return false
}

async function getMe() {
  return await request({
    endpoint: process.env.VUE_APP_IDENTITY_ME,
  })
}

export async function loginWithValidateRecaptcha(email, password, token) {
  return await request({
    method: 'POST',
    endpoint: process.env.VUE_APP_IDENTITY_LOGIN,
    body: {
      email,
      password,
      appType: 'AdminApplication',
      RecaptchaToken: token,
    },
  })
}

async function getUserInfo() {
  return sitesApi.get({ url: IDENTITY_URL + 'userinfo' })
}

export {
  login,
  checkUser,
  changePassword,
  loadToken,
  getAccessToken,
  getTokenByMFA,
  sendTokenToUser,
  isAuthenticated,
  getMe,
  getUserInfo,
}
