<template>
  <div v-if="breadcrumb.length" class="jsm-breadcrumb-wrapper">
    <div class="jsm-breadcrumb__inner-wrapper">
      <home-icon class="jsm-breadcrumb-home-icon"></home-icon>
      <div class="jsm-breadcrumb__list">
        <router-link
          v-for="(crumb, index) in breadcrumb"
          :key="index"
          :to="{ name: crumb.pathName }"
          tag="p"
          :class="{
            'jsm-breadcrumb__list--active': $route.name === crumb.pathName,
          }"
        >
          &nbsp;
          {{ crumb.name | capitalizeFirst(capitalizeStrings) }}
          {{ breadcrumb[index + 1] ? '/' : '' }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomeIcon from '../assets/images/home.svg'

function capitalize(value) {
  if (!value) return ''
  const val = value.toString()
  return val.charAt(0).toUpperCase() + val.slice(1)
}

export default {
  name: 'JsmBreadcrumb',

  components: {
    HomeIcon,
  },

  filters: {
    capitalizeFirst(value, capitalizeStrings) {
      if (!value) return ''
      if (!capitalizeStrings) return value
      const val = value.toString().toLowerCase().split(' ')

      return val.map((word) => capitalize(word)).join(' ')
    },
  },

  props: {
    capitalizeStrings: { type: Boolean, default: true },
    breadcrumb: { type: Array, default: () => [] },
  },
}
</script>

<style lang="stylus" src="./JsmBreadcrumb.styl" scoped />
