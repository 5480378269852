const ListInstitutional = () =>
  import(
    '../../views/institutional/components/list-institutional/ListInstitutional'
  )
const FormInstitutional = () =>
  import(
    '../../views/institutional/components/edit-institutional/EditInstitutional'
  )
const Institutional = () => import('../../views/institutional/Institutional')

export default {
  path: 'institutional',
  name: 'Institutional',
  redirect: 'institutional/list',
  component: Institutional,
  meta: { authenticate: true },
  children: [
    {
      name: 'ListInstitutional',
      path: 'list',
      component: ListInstitutional,
      meta: {
        breadcrumb: [
          {
            name: 'Marketing',
            pathName: '',
          },
          {
            name: 'Institucional',
            pathName: 'ListInstitutional',
          },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'CADASTRAR INSTITUCIONAL',
            to: 'NewInstitutional',
            tabTitle: 'CADASTRAR INSTITUCIONAL',
          },
        ],
      },
    },
    {
      name: 'EditInstitutional',
      path: 'edit/:id',
      component: FormInstitutional,
      meta: {
        isEdit: true,
        breadcrumb: [
          { name: 'Marketing', pathName: '' },
          { name: 'Institutional', pathName: 'ListInstitutional' },
          { name: 'Editar Institutional', pathName: 'EditInstitutional' },
        ],
      },
    },
    {
      name: 'NewInstitutional',
      path: 'new',
      component: FormInstitutional,
      meta: {
        isNew: true,
        breadcrumb: [
          { name: 'Marketing', pathName: '' },
          { name: 'Institutional', pathName: 'ListInstitutional' },
          { name: 'Novo Institutional', pathName: 'NewInstitutional' },
        ],
      },
    },
  ],
}
