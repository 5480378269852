import { storageKeys } from '@/enums/storageKeys'
import router from '@/router'
import storage from '@/services/localstorage.service'

const UNAUTHORIZED = 401

function getTemporaryToken() {
  return storage.get(process.env.VUE_APP_TEMPORARY_TOKEN)
}

const getToken = () => getTemporaryToken() || storage.get(storageKeys.TOKEN)

export const authorizationRequestInterceptor = (config) => {
  const token = getToken()
  token && (config.headers['authorization'] = `Bearer ${token}`)
  token && (config.headers['withCredentials'] = true)

  return config
}

export const authorizationResponseSuccessInterceptor = (response) => response

export const authorizationResponseErrorInterceptor = (error) => {
  const { status } = error?.response ?? {}

  if (status === UNAUTHORIZED) {
    storage.clear()
    router.push({ name: 'Login' })
  }

  return Promise.reject(error)
}
