<template>
  <div class="jsm-button-wrapper">
    <button
      :id="id"
      class="jsm-button__button circle"
      :class="`jsm-button__button--${type} jsm-button__button--${size}`"
      @click="($event) => $emit('click', $event)"
      v-ripple
      v-if="!disabled"
    >
      <component class="jsm-button__prefix" :is="prefix"></component>
      <p v-if="!loading">{{ text }}</p>
      <jsm-loading v-else type="small" isVisible transition="none" />
      <component class="jsm-button__suffix" :is="suffix"></component>
    </button>

    <button
      v-else
      :id="id"
      class="jsm-button__button circle"
      :class="{ 'jsm-button__button--disabled': disabled }"
      disabled
    >
      <p v-if="!loading">{{ text }}</p>
      <jsm-loading v-else type="small" isVisible transition="none" />
    </button>
  </div>
</template>

<script>
import JsmLoading from '../../jsm-loading'

import Ripple from './ripple/ripple'

export default {
  name: 'JsmButton',

  components: {
    JsmLoading,
  },

  directives: {
    ripple: Ripple,
  },

  props: {
    id: { type: String, default: '' },
    text: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    prefix: { type: Object, default: () => {} },
    suffix: { type: Object, default: () => {} },
    size: {
      type: String,
      default: 'medium',
      validator: (value) => ['small', 'medium', 'large'].indexOf(value) !== -1,
    },
    loading: { type: Boolean, default: false },
    type: {
      type: String,
      default: 'primary',
      validator: (value) =>
        [
          'nude-primary',
          'nude-warning',
          'nude-success',
          'nude',
          'primary',
          'warning',
          'success',
          'danger',
          'dark',
        ].indexOf(value) !== -1,
    },
  },
}
</script>

<style src="./JsmButton.styl" lang="stylus" scoped />
