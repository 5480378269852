const ListCategories = () =>
  import(
    /* webpackChunkName: "categorization" */ '../../views/categorization/components/list-categories/ListCategories.vue'
  )
const NewCategory = () =>
  import(
    /* webpackChunkName: "categorization" */ '../../views/categorization/components/new-category/NewCategory.vue'
  )
const Categories = () =>
  import(
    /* webpackChunkName: "categorization" */ '../../views/categorization/Categorization.vue'
  )

export default {
  path: 'categories',
  component: Categories,
  meta: { authenticate: true, clearStateOnChange: true },
  children: [
    {
      name: 'ListCategories',
      path: 'list',
      component: ListCategories,
      meta: {
        breadcrumb: [
          { name: 'Regras e Definições', pathName: '' },
          { name: 'Categorização', pathName: 'ListCategories' },
        ],
        actionButtons: [
          {
            type: 'nude-primary',
            text: 'CADASTRAR CATEGORIA',
            to: 'NewCategory',
            tabTitle: 'CADASTRAR CATEGORIA',
          },
        ],
        claims: ['CategoryAdmin.All'],
      },
    },
    {
      name: 'NewCategory',
      path: 'new',
      component: NewCategory,
      meta: {
        breadcrumb: [
          { name: 'Regras e Definições', pathName: '' },
          { name: 'Categorização', pathName: 'ListCategories' },
          { name: 'Nova Categoria', pathName: 'NewCategory' },
        ],
        claims: ['CategoryAdmin.All'],
      },
    },
  ],
}
