import { maxZIndex } from '../../helpers/functions'

import JsmLoading from './src/JsmLoading.vue'

JsmLoading.install = (Vue) => {
  let instance
  const vue = Vue
  let isBeingCalledCounter = 0
  vue.prototype.$loading = {
    show(args = {}) {
      isBeingCalledCounter += 1
      const { text, spinner, fontSize, fontFamily, color, type } = args
      const JsmLoadingConstructor = Vue.extend(JsmLoading)

      const initInstance = () => {
        instance = new JsmLoadingConstructor({
          el: document.createElement('div'),
        })
        if (text) instance.$props.text = text
        if (color) instance.$props.color = color
        if (spinner) instance.$props.spinner = spinner
        if (fontSize) instance.$props.fontSize = fontSize
        if (fontFamily) instance.$props.fontFamily = fontFamily
        if (type) instance.$props.type = type
      }

      if (!instance) {
        initInstance()
      }

      instance.visible = true

      Vue.nextTick(() => {
        if (instance) {
          document.body.appendChild(instance.$el)

          instance.$el.style.zIndex = maxZIndex() + 1
        }
      })
    },

    close() {
      isBeingCalledCounter -= 1
      if (instance && !isBeingCalledCounter) {
        instance.visible = false
        instance = null
      }
    },

    async showAndClose(promise) {
      this.show()

      let returningObject

      if (typeof promise === 'function') {
        returningObject = await promise()
      } else {
        returningObject = await promise
      }

      this.close()

      return returningObject
    },
  }
}

export default JsmLoading
