<template>
  <div class="main-left-nav-wrapper">
    <div
      class="main-left-nav__item"
      v-for="(item, idx) in items"
      :key="item.name"
      @click="openItem(item)"
      :class="[
        {
          'main-left-nav__item--show': hasRoles(item),
          'main-left-nav__item--last': isLast(idx),
          'main-left-nav__item--opened': item.isOpened,
        },
      ]"
    >
      <div>
        <component :is="item.icon" />
        <p class="main-left-nav__item-name">{{ item.name }}</p>
        <div v-if="item.showChildren" class="main-left-nav__item-child-menu">
          <div v-if="item.title" class="main-left-nav__item-title-wrapper">
            <p class="main-left-nav__item-title">{{ item.title }}</p>
          </div>

          <div v-if="!item.command">
            <div
              v-for="child in item.children"
              :key="child.name"
              :class="{
                'main-left-nav__item-child-menu--show': hasRoles(child),
              }"
            >
              <div
                v-if="hasRoles(child)"
                class="main-left-nav__item-child-menu-item"
                @click.stop="goTo(child.pathName)"
              >
                <p class="main-left-nav_item-child-menu-item-name">
                  {{ child.name }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-else
            class="main-left-nav__item-child-menu-item"
            v-for="child in item.children"
            :key="child.name"
            @click="handleCommand(child.command)"
          >
            <p class="main-left-nav_item-child-menu-item-name">
              {{ child.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="main-left-nav__backdrop"
      v-if="isBackdropOn"
      @click.stop="closeItems()"
    />
  </div>
</template>

<script>
import ConfigIcon from '@/assets/images/config.svg'
import FolderIcon from '@/assets/images/folder.svg'
import IndustryIcon from '@/assets/images/industry.svg'
import MegaphoneIcon from '@/assets/images/megaphone.svg'
import UserIcon from '@/assets/images/user.svg'
import { Mode } from '@/enums/environments.js'
import { maxZIndex } from '@/helpers/Functions'
import { useOnlyAtMode } from '@/mixins/feat-flag/feat-flag'

export default {
  data() {
    return {
      items: [
        {
          name: 'Indústria',
          icon: IndustryIcon,
          isOpened: false,
          showChildren: true,
          title: 'INDÚSTRIAS',
          roles: ['MasterAdmin', 'JSMAdmin'],
          children: [
            {
              roles: ['JSMAdmin'],
              name: 'Incluir/Editar Indústria',
              pathName: 'IndustryList',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Incluir/Editar Centro de Distribuição',
              pathName: 'ListDistributionCenter',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Incluir/Editar Região de Atuação',
              pathName: 'ListRestrictionRegions',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Incluir/Editar Região de Entrega',
              pathName: 'AddDeliveryRegion',
            },
          ],
        },
        {
          name: 'Regras e Definições',
          icon: ConfigIcon,
          title: 'Regras e Definições',
          isOpened: false,
          showChildren: true,
          roles: ['MasterAdmin', 'JSMAdmin'],
          children: [
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Produtos',
              pathName: 'ListProducts',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Categorização',
              pathName: 'ListCategories',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Restrições',
              pathName: 'ListRestrictions',
            },
            {
              roles: ['JSMAdmin'],
              name: 'Banners',
              pathName: 'ListBanners',
            },
            {
              roles: ['JSMAdmin'],
              name: 'Vitrines',
              pathName: 'ListCarousels',
            },
            {
              roles: ['JSMAdmin'],
              name: 'Recategorização',
              pathName: 'Recategorization',
            },
          ],
        },
        {
          roles: ['JSMAdmin'],
          name: 'Marketing',
          title: 'Marketing',
          icon: MegaphoneIcon,
          isOpened: false,
          showChildren: true,
          children: [
            {
              roles: ['JSMAdmin'],
              name: 'Institucional',
              pathName: 'ListInstitutional',
            },
          ],
        },
        {
          roles: ['MasterAdmin', 'JSMAdmin'],
          name: 'Relatórios',
          icon: FolderIcon,
          isOpened: false,
          showChildren: true,
          children: [
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Relatórios',
              pathName: 'ListReports',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Quero ser Cliente',
              pathName: 'ClientReports',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Relatórios Cartão de Crédito',
              pathName: 'CancellationReports',
            },
          ],
        },
        {
          name: 'Cliente',
          icon: UserIcon,
          title: 'Cliente',
          isOpened: false,
          showChildren: true,
          roles: ['MasterAdmin', 'JSMAdmin'],
          children: [
            {
              roles: ['MasterAdmin', 'JSMAdmin'],
              name: 'Listar usuários',
              pathName: 'UserList',
            },
            {
              roles: ['MasterAdmin', 'JSMAdmin', 'Coordinator', 'Salesman'],
              name: 'Acessar Como',
              pathName: 'ListAccessAs',
            },
          ],
        },
      ],
    }
  },

  computed: {
    isBackdropOn() {
      return this.items?.some((item) => item.isOpened)
    },
  },

  async mounted() {
    const childMenu = document.querySelector('.main-left-nav__item-child-menu')

    if (childMenu) childMenu.style.zIndex = maxZIndex()

    await this.$checkUserRoles()

    /* to-do [promotions]: Remove this function call when all is done*/
    this.applyFeatFlag(this.items)
  },

  methods: {
    handleCommand(command) {
      this[command]()
      this.closeItems()
    },

    hasRoles(item) {
      if (!item.roles) return false

      for (const role of item.roles) {
        if (this.$roles?.some((i) => i === role)) {
          return true
        }
      }
      return false
    },

    goTo(path) {
      this.$router.push({ name: path })
      this.closeItems()
    },

    openItem(item) {
      this.closeItems()
      item.isOpened = true
    },
    /* to-do [promotions]: Remove this feat flag when all is done*/
    applyFeatFlag(items) {
      const RULES_AND_DEFINITION_INDEX = 1
      if (!useOnlyAtMode(Mode.Prod)) {
        items[RULES_AND_DEFINITION_INDEX].children.push({
          roles: ['JSMAdmin'],
          name: 'Promoções',
          pathName: 'PromotionsList',
        })
      }
    },

    closeItems() {
      this.items.map((menuItem) => (menuItem.isOpened = false))
    },

    isLast(idx) {
      return this.items.length - 1 === idx
    },
  },
}
</script>

<style src="./MainLeftNav.styl" lang="stylus" />
