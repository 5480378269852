import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_INSIGHTS_KEY,
    autoTrackPageVisitTime: true,
    enableAutoRouteTracking: true,
    version: 2,
    disableCookiesUsage: true,
  },
})

export default appInsights
