export const errorConverter = (error) => {
  const responseData = (withValidation = false) =>
    withValidation ? error?.response?.data : error.response.data

  const isNewErrorResponse = !!responseData(true)?.type

  if (responseData(true)) {
    if (isNewErrorResponse) {
      error = responseData()
    } else {
      error.response = responseData()
    }
  }

  return error
}
