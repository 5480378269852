import guid from '@/helpers/guid'

export default class Observable {
  array
  observables

  constructor() {
    this.array = new Set()
    this.observables = new Map()
  }

  watch(observable) {
    const id = guid()
    this.observables.set(id, observable)
    return id
  }
  callObservables() {
    this.observables.forEach((callback) => {
      callback(!!this.array.size)
    })
  }

  updateArray(id, remove) {
    if (remove) {
      this.array.delete(id)
    } else {
      this.array.add(id)
    }
    this.callObservables()
  }
}
