import { maxZIndex } from '../../helpers/functions'

import JsmModal from './src/JsmModal.vue'

JsmModal.install = (Vue) => {
  let instance
  const vue = Vue
  const modal = {
    show(main, message) {
      const CmModalConstructor = Vue.extend(JsmModal)

      const initInstance = () => {
        instance = new CmModalConstructor({
          el: document.createElement('div'),
          props: {},
        })
        instance.$props.message = message
        instance.$slots.default = instance.$createElement(main)
      }

      if (!instance) {
        initInstance()
      }

      instance.visible = true

      Vue.nextTick(() => {
        document.body.appendChild(instance.$el)

        instance.$el.style.zIndex = maxZIndex() + 1
      })
    },

    close() {
      if (instance) {
        instance.visible = false
        instance = null
      }
    },
  }

  vue.modal = modal
  vue.prototype.$modal = modal
}

export default JsmModal
