const getComponentNameFromTrace = /--->\s<([A-Z]\w+)>/gi
const fontSize = 'font-size: 13px;'
const contextStyle = `${fontSize} font-weight: bold;`
const contrastStyle = `${fontSize} font-style: italic; color: rgb(237, 92, 92);`

const consoleHelper = (messages) => {
  messages.forEach((msg) => {
    const style = msg.style ? [msg.style] : [contextStyle, contrastStyle]
    console.log(msg.text, ...style)
  })
}
export default (errorMessage, vueInstance, trace) => {
  //Custom handler for runtime Vue warnings. Note this only works during development and is ignored in production.
  const componentName = getComponentNameFromTrace.exec(trace)?.[1]
  const messages = [
    { text: `%c 👉That 💩💩 (error) has occured because: %c ${errorMessage}` },
    { text: `%c 👉Component that triggered the error: %c ${componentName}` },
    { text: `%c 👉Vue Error tracking: %c ${trace}` },
    { text: '%c The end and good luck 👀', style: fontSize },
  ]
  console.group(
    '%c Hi, JSM Lover! 👋👋 it looks like some error has occurred:',
    'font-size: 15px'
  )
  consoleHelper(messages)
  console.groupEnd()
}
