<template>
  <div class="main-view-wrapper">
    <main-navbar v-if="!$mob" />
    <responsive-nav-bar v-else />
    <div class="main-view-inner-wrapper">
      <main-left-nav v-if="!$mob" />
      <div class="main-view-container">
        <jsm-breadcrumb :breadcrumb="stateBreadcrumb"></jsm-breadcrumb>
        <router-view></router-view>
        <div class="main-view__footer">
          <p>
            Juntos Somos Mais <strong>CNPJ:</strong> 29.894.630/0001-39 | ©
            Todos os direitos reservados - 2018 | Privacidade
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as typesBreadcrumb from '@/store/modules/breadcrumb/types'

import MainLeftNav from './components/main-left-nav/MainLeftNav.vue'
import MainNavbar from './components/main-navbar/MainNavbar.vue'
import ResponsiveNavBar from './components/responvie-nav-bar/ResponviseNavBar.vue'

export default {
  components: {
    MainNavbar,
    MainLeftNav,
    ResponsiveNavBar,
  },

  computed: {
    ...mapState(typesBreadcrumb.NAMESPACE, {
      stateBreadcrumb: typesBreadcrumb.STATE_BREADCRUMB,
    }),
  },

  mounted() {
    this.$checkUserRoles()
  },
}
</script>

<style src="./Main.styl" lang="stylus" scoped />
