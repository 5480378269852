<template>
  <div class="jsm-metter">
    <meter
      class="jsm-metter__progress"
      :value="passwordScore"
      low="25"
      high="75"
      max="100"
      optimum="100"
    ></meter>
    <p class="jsm-metter__status" v-html="passwordStrength">
      {{ passwordStrength }}
    </p>
    <ul class="jsm-metter__password-rules">
      <li
        class="jsm-metter__password-rules-item"
        :class="{
          'jsm-metter__password-rules-item__success':
            this.minLengthSucess === true,
        }"
      >
        Precisa no mínimo de 8 caracteres
      </li>

      <li
        class="jsm-metter__password-rules-item"
        :class="{
          'jsm-metter__password-rules-item__success':
            this.notSequencialSucess === true,
        }"
      >
        Não conter sequência
      </li>

      <li
        class="jsm-metter__password-rules-item"
        :class="{
          'jsm-metter__password-rules-item__success':
            this.mediumSuccess === true,
        }"
      >
        Ter ao menos um caractere em maiúsculo
      </li>

      <li
        class="jsm-metter__password-rules-item"
        :class="{
          'jsm-metter__password-rules-item__success': this.fullSuccess === true,
        }"
      >
        Conter ao menos um caractere Especial
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'JsmMetter',

  props: {
    value: { type: String, default: '' },
  },

  data() {
    return {
      passwordStrength: '',
      passwordScore: null,
      notSequencialSucess: false,
      minLengthSucess: false,
      mediumSuccess: false,
      fullSuccess: false,
      securePassword: false,
    }
  },

  mount() {},

  watch: {
    value(value) {
      const strongPassWord = new RegExp('[^0-9a-zA-Z *]', 'g')
      const mediumPassWord = new RegExp('^(?=.*[A-Z]).*$', 'g')

      const sequencialNumbers =
        /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/

      // const sequencialNumbers = /(?:(?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)){2,}\d|(?:a(?=b)|b(?=c)|c(?=d)|d(?=e)|e(?=f)|f(?=g)|g(?=h)|h(?=i)|i(?=j)|j(?=k)|k(?=l)|l(?=m)|m(?=n)|n(?=o)|o(?=p)|p(?=q)|q(?=r)|r(?=s)|s(?=t)|t(?=u)|u(?=v)|v(?=w)|w(?=x)|x(?=y)|y(?=z)){2,}[[:alpha:]])|([a-zA-Z0-9])\1\1+/;

      // if (sequencialNumbers.test(value)) {
      //   console.log('tem sequencia');
      // }

      if (!sequencialNumbers.test(value)) {
        this.notSequencialSucess = true
        this.passwordScore = this.passwordScore + 25
      } else {
        this.notSequencialSucess = false
      }

      if (value.length >= 8) {
        this.minLengthSucess = true
        this.passwordScore = this.passwordScore + 25
      } else {
        this.minLengthSucess = false
      }
      if (mediumPassWord.test(value)) {
        this.mediumSuccess = true
        this.passwordScore = this.passwordScore + 25
      } else {
        this.mediumSuccess = false
      }
      if (strongPassWord.test(value)) {
        this.passwordScore = this.passwordScore + 25
        this.fullSuccess = true
      } else {
        this.fullSuccess = false
        this.passwordScore = 0
      }

      if (
        this.minLengthSucess === true &&
        this.mediumSuccess === true &&
        this.fullSuccess === true &&
        this.notSequencialSucess === true
      ) {
        this.securePassword = true
        this.securitePassword()
      } else {
        this.securePassword = false
        this.inScuritePassword()
      }
    },
  },

  methods: {
    securitePassword() {
      this.$emit('isSecurePassword')
    },

    inScuritePassword() {
      this.$emit('isInSecurePassword')
    },
  },
}
</script>

<style scoped lang="stylus">
.jsm-metter {
  width: 100%;
  margin-top: var(--spacing-medium);
}

.jsm-metter__progress {
  margin: 0 auto 1em;
  display: block;
  width: 100%;
  height: 6px;
}

meter[value='0']:-moz-meter-sub-optimum::-moz-meter-bar {
  background: red;
}

meter[value='0']:-webkit-meter-optimum-value {
  background: red;
}

meter[value='25']:-webkit-meter-optimum-value {
  background: orange;
}

meter[value='25']:-moz-meter-sub-optimum::-moz-meter-bar {
  background: orange;
}

meter[value='75']:-webkit-meter-optimum-value {
  background: yellow;
}

meter[value='75']:-moz-meter-sub-optimum::-moz-meter-bar {
  background: yellow;
}

meter[value='100']:-webkit-meter-optimum-value {
  background: green;
}

meter[value='100']:-moz-meter-sub-optimum::-moz-meter-bar {
  background: green;
}

.jsm-metter__password-rules {
  list-style-type: disc;
  padding-left: var(--spacing-base);
}

.jsm-metter__password-rules-item {
  font var(--text-body-small);
  letter-spacing var(--text-body-small-letter);
  margin-bottom: var(--spacing-xxsmall);
  text-align: left;
}

.jsm-metter__password-rules-item__success {
  color: var(--color-contextual-success-light-1);

  meter {
    -webkit-appearance: none;
    background: gray;
  }

  meter > div {
    height: 100%;
  }

  meter[value='1'] > div {
    width: 25%;
    background: red;
  }

  meter[value='2'] > div {
    width: 50%;
    background: yellow;
  }

  meter[value='3'] > div {
    width: 75%;
    background: orange;
  }

  meter[value='4'] > div {
    width: 100%;
    background: green;
  }
}
</style>
