const RestrictionRegions = () =>
  import(
    /* webpackChunkName: "restriction-regions" */ '../../views/restriction-regions/RestrictionRegions.vue'
  )

const ListRestrictionRegions = () =>
  import(
    /* webpackChunkName: "restriction-regions-list" */ '../../views/restriction-regions/components/list/List.vue'
  )

const AddRestrictionRegions = () =>
  import(
    /* webpackChunkName: "restriction-regions-add" */ '@/views/restriction-regions/components/add/AddRestrictionRegions.vue'
  )

export default {
  path: 'restriction-regions',
  component: RestrictionRegions,
  meta: { authenticate: true },
  children: [
    {
      name: 'ListRestrictionRegions',
      path: 'list',
      component: ListRestrictionRegions,
      props: true,
    },
    {
      name: 'AddRestrictionRegions',
      path: 'add',
      component: AddRestrictionRegions,
    },
  ],
}
