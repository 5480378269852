var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jsm-table-pagination__wrapper"},[_c('div',{staticClass:"jsm-table-pagination__item previous-button",class:{
      'jsm-table-pagination__previous-button--inactive':
        _vm.pages[0] && _vm.pages[0].active === true,
    },on:{"click":_vm.previousPage}},[_c('p',[_vm._v("Anterior")])]),(_vm.pages.length < 7)?_c('div',{staticClass:"jsm-table-pagination__container"},_vm._l((_vm.pages),function(page,index){return _c('div',{key:index,staticClass:"jsm-table-pagination__item",class:{ 'jsm-table-pagination__item--active': page.active },on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(" "+_vm._s(page.index + 1)+" ")])}),0):_vm._e(),(_vm.pages.length >= 7)?_c('div',{staticClass:"jsm-table-pagination__container"},[(_vm.firsts)?_c('div',{staticClass:"jsm-table-pagination__container"},_vm._l((_vm.firsts),function(page,index){return _c('div',{key:index,staticClass:"jsm-table-pagination__item",class:{ 'jsm-table-pagination__item--active': page.active },on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(" "+_vm._s(page.index + 1)+" ")])}),0):_vm._e(),(_vm.ativePage >= 4)?_c('div',{staticClass:"jsm-table-pagination__container"},[_c('span',{staticClass:"jsm-table-pagination__item"},[_vm._v("...")])]):_vm._e(),(_vm.ativePage >= 3 && _vm.ativePage < _vm.pages.length - 3)?_c('div',{staticClass:"jsm-table-pagination__container"},[_c('span',{staticClass:"jsm-table-pagination__item jsm-table-pagination__item--active"},[_vm._v(_vm._s(_vm.ativePage + 1))])]):_vm._e(),(_vm.ativePage < _vm.pages.length - 3)?_c('div',{staticClass:"jsm-table-pagination__container"},[_c('span',{staticClass:"jsm-table-pagination__item"},[_vm._v("...")])]):_vm._e(),(_vm.lasts)?_c('div',{staticClass:"jsm-table-pagination__container"},_vm._l((_vm.lasts),function(page,index){return _c('div',{key:index,staticClass:"jsm-table-pagination__item",class:{ 'jsm-table-pagination__item--active': page.active },on:{"click":function($event){return _vm.setPage(page)}}},[_vm._v(" "+_vm._s(page.index + 1)+" ")])}),0):_vm._e()]):_vm._e(),_c('div',{staticClass:"jsm-table-pagination__item next-button",class:{
      'jsm-table-pagination__next-button--inactive':
        !_vm.pages.length ||
        (_vm.pages[_vm.pages.length - 1] && _vm.pages[_vm.pages.length - 1].active),
    },on:{"click":_vm.nextPage}},[_c('p',[_vm._v("Próximo")])]),_c('div',{staticClass:"jsm-table-pagination__item next-button",class:{
      'jsm-table-pagination__next-button--inactive':
        !_vm.pages.length ||
        (_vm.pages[_vm.pages.length - 1] && _vm.pages[_vm.pages.length - 1].active),
    },on:{"click":_vm.lastPage}},[_c('p',[_vm._v("Última")])])])}
var staticRenderFns = []

export { render, staticRenderFns }