import { storageKeys } from '@/enums/storageKeys'
import { checkUserIsAdmin } from '@/helpers/UserRole.js'
import persistence from '@/services/localstorage.service'

function checkUserRoles() {
  return new Promise((resolve) => {
    let counter = 0
    const interval = setInterval(() => {
      const user = persistence.get(storageKeys.USER_INFO)
      counter += 1

      if (user) {
        clearInterval(interval)

        resolve(user)
      }

      if (counter > 1) {
        clearInterval(interval)

        resolve(null)
      }
    }, 1000)
  })
}

async function userHasPermissions(route) {
  if (!route.meta.claims) return true

  const user = await checkUserRoles()

  if (!user) return false

  if (user.isJSMAdmin || checkUserIsAdmin(user.roles)) {
    return true
  }
  const permissionsBySellers = user?.permissionsBySellers?.[0]

  if (!permissionsBySellers) return false

  const filteredClaims = permissionsBySellers?.permissions?.filter((claim) => {
    return route.meta.claims.includes(claim)
  })

  return filteredClaims.length > 0
}

const install = (Vue) => {
  const reactorComponent = new Vue({
    data: () => ({
      userRoles: [],
      permissionsBySellers: [],
    }),
  })

  Vue.mixin({
    computed: {
      $roles() {
        return reactorComponent.userRoles
      },

      $permissionsBySellers() {
        return reactorComponent.permissionsBySellers
      },

      $isJSMAdmin() {
        return this.$hasRole('JSMAdmin')
      },
    },

    methods: {
      async $checkUserRoles() {
        const user = await checkUserRoles()
        reactorComponent.permissionsBySellers = user?.permissionsBySellers
        reactorComponent.userRoles = user?.roles
        return this
      },

      $hasRole(roleName) {
        return this.$roles.some((role) => role === roleName)
      },

      $userHasPermissions(route) {
        return userHasPermissions(route)
      },
    },
  })
}

export { install, userHasPermissions, checkUserRoles }
