import { storageKeys } from '@/enums/storageKeys'
import mockSellers from '@/mocks/sellers'
import persistence from '@/services/localstorage.service'

const sellers = () =>
  process.env.NODE_ENV !== 'test'
    ? persistence.get(storageKeys.USER_SELLERS)
    : mockSellers

const getSellerSelectFormat = (id) => {
  return !id
    ? sellers().map((eachSeller) => ({
        text: eachSeller.name,
        id: eachSeller.id,
        prefix: `<img src="${eachSeller.guidImage}">`,
      }))
    : sellers()
        .filter((s) => s.id === id)
        .map((i) => ({
          text: i.name,
          id: i.id,
          prefix: `<img src="${i.guidImage}">`,
        }))[0]
}

const getSeller = (id) => {
  const [seller] = sellers().filter((s) => s.id === id)

  return seller
}

const getSellerName = (id) => {
  const [seller] = sellers()
    .filter((s) => s.id === id)
    .map((i) => i.nameCompany)

  return seller
}

const install = (Vue) => {
  Vue.mixin({
    computed: {
      $getAllSellers() {
        return sellers()
      },
    },
    methods: {
      $seller(id) {
        return getSeller(id)
      },
      $sellerForSelect(id) {
        return getSellerSelectFormat(id)
      },
      $sellerName(id) {
        return getSellerName(id)
      },
    },
  })
}

export { install, getSeller }
