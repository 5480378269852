<template>
  <div class="main-navbar__wrapper" v-if="!$mob">
    <div class="main-navbar__boxinfo">
      <img
        class="main-navbar__boxinfo-logo"
        alt="Vue logo"
        src="@/assets/images/logo_jsm.png"
      />
      <h1 class="main-navbar__boxinfo-pagename">Loja virtual</h1>
    </div>
    <div class="main-navbar__usermenu">
      <user-logo class="main-navbar__usermenu-icon"></user-logo>
      <p class="main-navbar__usermenu-username">{{ user && user.name }}</p>
      <p @click="logout" class="main-navbar__logout">Sair</p>
    </div>
  </div>
</template>

<script>
import UserLogo from '@/assets/images/user-inverse.svg'
import { storageKeys } from '@/enums/storageKeys'

export default {
  components: {
    UserLogo,
  },

  mounted() {
    this.user = this.$persistence.get(storageKeys.USER_INFO)
  },

  data() {
    return {
      user: '',
    }
  },

  methods: {
    logout() {
      this.$persistence.clear()
      this.$router.push({ name: 'Login' })
      this.$checkUserRoles()
    },
  },
}
</script>

<style src="./MainNavbar.scss" scoped lang="scss" />
