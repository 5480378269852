const DeliveryRegion = () =>
  import(
    /* webpackChunkName: "delivery-region" */ '../../views/delivery-region/DeliveryRegion.vue'
  )

const AddDeliveryRegion = () =>
  import(
    /* webpackChunkName: "delivery-region" */ '../../views/delivery-region/components/AddDeliveryRegion.vue'
  )

export default {
  path: 'delivery-region',
  component: DeliveryRegion,
  meta: { authenticate: true },
  children: [
    {
      name: 'AddDeliveryRegion',
      path: '/',
      component: AddDeliveryRegion,
      meta: {
        breadcrumb: [
          { name: 'Administração', pathName: '' },
          { name: 'Região de Entrega', pathName: 'AddDeliveryRegion' },
        ],
      },
    },
  ],
}
